import React, {Component} from 'react';
import Measure from 'react-measure';
import moment from 'moment';
import {nest as d3Nest} from 'd3-collection';
import {scaleLog, scaleTime} from 'd3-scale';
import {format} from 'd3-format';
import {extent, mean, quantile, max} from 'd3-array';
import SimpleAnnotation from '../SimpleAnnotation';
import TopNews from './TopNews';
import {calculatePopularity, TAGS, colors, colorsLow, getRetinaRatio} from '../../Helpers/utils';

import './daybyday.scss';

const LOW = 'p05';
const HIGH = 'p95';

const MARGINS = {
  top: 25,
  bottom: 25,
  left: 120,
  right: 120,
};

const stories = [
  {
    id: 'all',
    text: 'all',
    annotationText: [
      {
        text: 'Most popular news',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: () => 1,
  },
  {
    id: 'nda',
    text: 'Only NDA',
    annotationText: [
      {
        text: 'Most popular news about NDA',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about NDA',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => {
      // console.log('nda', d.slugs);
      return (
        d.slugs.indexOf('nda') > -1 &&
        (d.tags.indexOf('modi') === -1 || d.tags.indexOf('narendra modi') === -1)
      );
    },
  },
  {
    id: 'upa',
    text: 'Only UPA',
    annotationText: [
      {
        text: 'Most popular news about UPA',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about UPA',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => {
      // console.log('upa', d.slugs, d.slugs.indexOf('upa') > -1);
      return d.slugs.indexOf('upa') > -1 && d.tags.indexOf('rahul gandhi') === -1;
    },
  },
  {
    id: 'mahagathbandhan',
    text: 'Only Mahagathbandhan',
    annotationText: [
      {
        text: 'Most popular news about Mahagathbandhan',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about Mahagathbandhan',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => d.slugs.indexOf('mahagathbandhan') > -1,
  },
  // {
  //   id: "top",
  //   text: "Only top",
  //   annotationText: [{
  //     text: "Most popular news"}],
  //   filterFunc: (d, threshold) => d.popularity >= threshold
  // },
  {
    id: 'modi',
    text: 'Only Narendra Modi',
    annotationText: [
      {
        text: 'Most popular news about Narendra Modi',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about Narendra Modi',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => d.tags.indexOf('modi') > -1 || d.tags.indexOf('narendra modi') > -1,
  },
  {
    id: 'rahul',
    text: 'Only Rahul Gandhi',
    annotationText: [
      {
        text: 'Most popular news about Rahul Gandhi',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about Rahul Gandhi',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => d.tags.indexOf('rahul gandhi') > -1,
  },
  {
    id: 'mamata',
    text: 'Only Mamata Banerjee',
    annotationText: [
      {
        text: 'Most popular news about Mamata Banerjee',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about Mamata Banerjee',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => d.tags.indexOf('mamata banerjee') > -1,
  },
  {
    id: 'yogi',
    text: 'Only Yogi Adityanath',
    annotationText: [
      {
        text: 'Most popular news about Yogi Adityanath',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about Yogi Adityanath',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => d.tags.indexOf('yogi adityanath') > -1,
  },
  {
    id: 'kejriwal',
    text: 'Only Arvind Kejriwal',
    annotationText: [
      {
        text: 'Most popular news about Arvind Kejriwal',
        align: 'left',
      },
      {
        text: 'Most popular news yesterday about Arvind Kejriwal',
        subFilterFunc: d =>
          d.day ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      },
    ],
    filterFunc: d => d.tags.indexOf('arvind kejriwal') > -1,
  },
];

const drawArticle = props => {
  const {ctx, x, y, stroke = 'transparent', color, width = 5, height = 5} = props;
  // console.log('drawing circle', x, y, radius);
  ctx.save();
  ctx.beginPath();
  ctx.translate(x, y);
  ctx.rotate((45 * Math.PI) / 180);
  ctx.fillStyle = color;
  ctx.strokeStyle = stroke;
  ctx.lineWidth = 0.5;
  ctx.rect(-width / 2, -height / 2, width, height);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  // ctx.setTransform(1, 0, 0, 1, 0, 0);
};

class DayByDay extends Component {
  state = {
    error: false,
    samples: [],
    annotations: [],
    extents: {
      popularity: [0, 0],
      days: [new Date(), new Date()],
      zscore: [0, 0],
    },
    dimensions: {
      width: 0,
      height: 0,
    },
    margins: {
      ...MARGINS,
    },
    days: [],
    storyIndex: 0,
  };

  ratio = 1;

  componentDidMount() {
    this.ratio = getRetinaRatio();
    fetch(`/data/articles-by-day.json`) // (`https://india.elezioni.io/v0/stats/articles-by-day`)
      .then(response => {
        if (response.status === 500) {
          return {
            error: true,
            ...response,
          };
        }

        return response.json();
      })
      .then(data => {
        // console.log('parsed json', data.data);
        if (data.error) {
          this.setState({
            error: true,
          });
        }
        let samples = [];
        // const annotated = {};
        data.data.days
          .filter(d => new Date(d.day) >= new Date(2019, 2, 23))
          .forEach(day => {
            day.articles.forEach(article => {
              if (article.tags === '') {
                return;
              }
              const slugs = [];
              const tags = article.tags || '';
              tags.split(',').forEach(tag => {
                Object.keys(TAGS).forEach(d => {
                  if (TAGS[d].indexOf(tag) > -1) {
                    if (slugs.indexOf(d) === -1) {
                      slugs.push(d);
                    }
                  }
                });
              });

              const popularity = calculatePopularity(article);

              slugs.forEach(d => {
                const slug = d.split('-')[0];
                samples.push({
                  id: `${article.id}-${slug}`,
                  popularity,
                  date: moment(day.day)
                    .utc()
                    .toDate(),
                  day: day.day,
                  slugs: [slug],
                  tags: article.tags,
                });
              });
            });
          });

        // samples = computeZScore(samples.filter(d => !isNaN(d.popularity) && d.slugs.length), d => d.popularity);
        samples = samples
          .filter(d => !Number.isNaN(d.popularity) && d.popularity > 0 && d.slugs.length)
          .sort((a, b) => a.popularity - b.popularity);
        const sortedSamples = samples.map(d => d.popularity);

        const filteredSamples = samples.filter(stories[this.state.storyIndex].filterFunc);

        this.setState({
          samples,
          annotations: filteredSamples.length
            ? stories[this.state.storyIndex].annotationText.map(annotation => {
                const subFilteredSamples = annotation.subFilterFunc
                  ? filteredSamples.filter(annotation.subFilterFunc)
                  : filteredSamples;

                return {
                  ...subFilteredSamples[subFilteredSamples.length - 1],
                  annotation,
                  text: annotation.text,
                  align: annotation.align || 'right',
                };
              })
            : [],
          // annotations: Object.values(annotated), // [top],
          // median: median(sortedSamples),
          // mean: mean(sortedSamples),
          percentiles: {
            p05: quantile(sortedSamples, 0.05),
            p10: quantile(sortedSamples, 0.1),
            p25: quantile(sortedSamples, 0.25),
            p50: quantile(sortedSamples, 0.5),
            p75: quantile(sortedSamples, 0.75),
            p90: quantile(sortedSamples, 0.9),
            p95: quantile(sortedSamples, 0.95),
          },
          extents: {
            popularity: extent(samples, d => d.popularity),
            days: extent(samples, d => d.date),
            // zscore: extent(samples, d=>d.zscore),
          },
          days: d3Nest()
            .key(d => d.day)
            .rollup(leaves => {
              return {
                max: max(leaves, d => d.popularity),
                news: leaves.sort((a, b) => a.popularity - b.popularity),
              };
            })
            .entries(samples),
        });
      })
      .catch(ex => {
        console.log('parsing failed', ex);
      });
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  updateCanvas() {
    if (this.canvas) {
      // console.log('updateCanvas');
      const ctx = this.canvas.getContext('2d');

      // ctx.globalCompositeOperation = 'luminosity';
      // console.log(`RATIO: ${this.ratio}`);
      ctx.scale(this.ratio, this.ratio);
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height); // clear the canvas
      // console.log('INDEX', this.state.storyIndex)
      this.state.samples
        .filter(d => stories[this.state.storyIndex].filterFunc(d, this.state.percentiles[HIGH]))
        .forEach(d => {
          const x = this.xScale(d.date);

          const y = this.yScale(d.popularity);

          const interesting =
            d.popularity < this.state.percentiles[LOW] ||
            d.popularity > this.state.percentiles[HIGH];
          // console.log('drawArticle', d.slugs[0], colors[d.slugs[0]]);
          drawArticle({
            ctx,
            x,
            y,
            color: interesting ? colors[d.slugs[0]] : colorsLow[d.slugs[0]],
            stroke: 'transparent', // interesting ? "#000" : "transparent"
          });
        });

      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }
  }

  nextStory() {
    const storyIndex = (this.state.storyIndex + 1) % stories.length;
    const filteredSamples = this.state.samples.filter(stories[storyIndex].filterFunc);

    this.setState({
      storyIndex,
      annotations: filteredSamples.length
        ? stories[storyIndex].annotationText.map(annotation => {
            const subFilteredSamples = annotation.subFilterFunc
              ? filteredSamples.filter(annotation.subFilterFunc)
              : filteredSamples;

            return {
              ...subFilteredSamples[subFilteredSamples.length - 1],
              text: annotation.text,
              align: annotation.align || 'right',
            };
          })
        : [],
    });
  }

  render() {
    const {
      extents,
      percentiles,
      dimensions,
      margins,
      annotations,
      days,
      storyIndex,
      error,
    } = this.state;
    const {width, height} = dimensions;
    // console.log(samples)
    if (error) {
      return null;
    }
    // console.log('--->', annotations);
    this.xScale = scaleTime()
      .domain([extents.days[0], new Date(2019, 4, 31)])
      // .domain([new Date(2019, 2, 23), new Date(2019, 4, 23)])
      .rangeRound([margins.left, width - margins.right]);
    this.yScale = scaleLog()
      .domain(extents.popularity)
      .range([height - margins.bottom, margins.top]);
    const dayAverage = Math.round(mean(days, d => d.value.news.length));

    return (
      <div className="day-by-day-plot">
        <div className="component-wrapper">
          <h2>Popularity Day by day</h2>
          <div className="cols">
            <p>
              We recorded on average <b>{Number.isNaN(dayAverage) ? '' : dayAverage}</b> news every
              day about the Indian General Election. Since we started we have been noticed a steady
              increase
              <span role="img" aria-label="increasing chart">
                📈
              </span>{' '}
              in the amount of the news as we approached the 11th of April, when the people started
              to go to the polls.
            </p>
            <p>
              The following chart shows <b>{format(',.0d')(this.state.samples.length)}</b>
              <span role="img" aria-label="Screaming cat">
                🙀
              </span>
              news in a single view and highlights the most and least popular news for the
              coalitions and their leaders.
            </p>
          </div>
        </div>
        <div className="chart--wrapper">
          <figure className="day-by-day-plot--chart">
            <div className="in">
              <Measure
                bounds
                onResize={contentRect => {
                  // onResize is called several times, we want to be sure we are not triggering an update cycle when the dimensions have not changed
                  if (
                    this.state.dimensions.width !== contentRect.bounds.width ||
                    this.state.dimensions.height !== contentRect.bounds.height
                  ) {
                    this.setState({
                      dimensions: {
                        width: contentRect.bounds.width,
                        height: contentRect.bounds.height,
                      },
                      margins: {
                        ...MARGINS,
                        left: contentRect.bounds.width < 960 ? 0 : MARGINS.left,
                        right: contentRect.bounds.width < 960 ? 0 : MARGINS.right,
                      },
                    });
                  }
                }}
              >
                {({measureRef}) => (
                  <div ref={measureRef} className="day-by-day-plot--wrapper">
                    <ul className="axis x-axis">
                      {this.xScale.ticks().map(d => {
                        // console.log(d, this.xScale(d));
                        const liStyle = {
                          left: `${this.xScale(d)}px`,
                        };
                        const label = moment(d).format('MMM Do');

                        return (
                          <li key={+d} style={liStyle} data-date={d.toString()}>
                            <span>{label}</span>
                          </li>
                        );
                      })}
                    </ul>
                    <canvas
                      ref={el => {
                        this.canvas = el;
                      }}
                      width={this.state.dimensions.width * this.ratio}
                      height={this.state.dimensions.height * this.ratio}
                      style={{
                        width: `${this.state.dimensions.width}px`,
                        height: `${this.state.dimensions.height}px`,
                      }}
                    />
                    {percentiles && (
                      <div
                        className="avg percentile p-range"
                        style={{
                          top: `${this.yScale(percentiles[HIGH])}px`,
                          bottom: `calc(100% - ${this.yScale(percentiles[LOW])}px)`,
                        }}
                      />
                    )}
                    {percentiles && (
                      <div
                        className="avg percentile p-low"
                        style={{top: `${this.yScale(percentiles[LOW])}px`}}
                      >
                        <span className="label label-below">&darr; least popular</span>
                      </div>
                    )}
                    {percentiles && (
                      <div
                        className="avg percentile p-high"
                        style={{top: `${this.yScale(percentiles[HIGH])}px`}}
                      >
                        <span className="label label-above">&uarr; most popular</span>
                      </div>
                    )}
                    {days.length > 0 && (
                      <TopNews
                        id={stories[storyIndex].id}
                        data={days
                          .map(d => {
                            if (!d.value || !d.value.news || !d.value.news.length) {
                              return null;
                            }
                            const filteredNews = d.value.news.filter(
                              stories[storyIndex].filterFunc,
                            );
                            const news = filteredNews[filteredNews.length - 1];

                            if (!news) {
                              return null;
                            }

                            return {
                              ...news,
                              id: news.id.split('-')[0],
                              x: this.xScale(new Date(news.date)),
                              y: this.yScale(news.popularity),
                            };
                          })
                          .filter(d => d)}
                        storyNewsIds={annotations
                          .filter(d => d.id)
                          .map(d => ({
                            id: d.id.split('-')[0],
                            title: d.text,
                          }))}
                        margins={{
                          top: percentiles ? this.yScale(percentiles[HIGH]) : 0,
                          bottom: percentiles ? this.yScale(percentiles[LOW]) : 0,
                        }}
                      />
                    )}
                    {annotations
                      .filter(d => d.id)
                      .map((d, index) => {
                        const x = this.xScale(d.date);
                        const y = this.yScale(d.popularity);
                        if (
                          y >= this.yScale(percentiles[HIGH]) - 15 &&
                          y <= this.yScale(percentiles[LOW]) + 15
                        ) {
                          return null;
                        }

                        let align = d.align === 'right' ? 'right' : 'left';

                        if (x > width - 150) {
                          align = 'left';
                        }
                        if (x < 150) {
                          align = 'right';
                        }
                        const key = `${d.id}-${index}`;

                        return (
                          <SimpleAnnotation
                            {...{
                              key,
                              left: `${x + (align === 'right' ? 5 : -5)}px`,
                              top: `${y - 1}px`,
                              // width: "100px",
                              orientation:
                                align === 'right'
                                  ? 'straight right-to-left'
                                  : 'straight left-to-right',
                              id: d.id,
                              text: {
                                en: d.text, // stories[this.state.storyIndex].annotationText.text
                              },
                              news: d,
                            }}
                          />
                        );
                      })}
                    <div
                      className="stories-arrow"
                      onClick={() => this.nextStory()}
                      onKeyUp={this.nextStory}
                      role="button"
                      tabIndex="0"
                    >
                      &rarr;
                    </div>
                  </div>
                )}
              </Measure>
            </div>
          </figure>
        </div>
      </div>
    );
  }
}

export default DayByDay;
