import React, {Component} from 'react';
import './LollipopChart.css';

class LollipopChart extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();

    this.draw = this.draw.bind(this);

    this.state = {
      bullets: [],
      labels: [],
      lines: [],
    };
  }

  componentDidMount() {
    this.draw();
  }

  componentDidUpdate(pProps, pState) {
    if (pProps !== this.props) {
      this.draw();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.viewbox, false);
  }

  draw() {
    if (this.props.Series && this.props.Series.length === 2) {
      const global = this.props.Data.global[this.props.Plot];
      if (global) {
        // console.log(this.props.Series);
        // left
        const left = this.props.Data[this.props.Series[0].id][this.props.Plot];
        const right = this.props.Data[this.props.Series[1].id][this.props.Plot];

        // console.log(left, right, global);

        const leftPercent = Math.round((100 * left) / global);
        const rightPercent = Math.round((100 * right) / global);

        this.setState({
          bullets: [
            {cssStyles: {right: `${leftPercent}%`}},
            {cssStyles: {left: `${rightPercent}%`}},
          ],
          labels: [
            {cssStyles: {right: `${leftPercent}%`}, label: leftPercent},
            {cssStyles: {left: `${rightPercent}%`}, label: rightPercent},
          ],
          lines: [
            {cssStyles: {width: `${leftPercent}%`, right: 0}},
            {cssStyles: {width: `${rightPercent}%`, left: 0}},
          ],
        });
      }
    }
  }

  render() {
    if (this.state.lines.length === 2) {
      return (
        <div className="LollipopChart" ref={this.chart}>
          <div className={`left-wrapper ${this.props.Series[0].id}`}>
            <div className="in w-separator">
              <div className="line" style={this.state.lines[0].cssStyles} />
              <div className="bullet" style={this.state.bullets[0].cssStyles} />
              <div className="label" style={this.state.labels[0].cssStyles}>
                <b>{this.state.labels[0].label}%</b>
                {this.props.Series[0].labelAddOn && (
                  <span className="labelAddOn">{this.props.Series[0].labelAddOn}</span>
                )}
              </div>
            </div>
          </div>
          <div className={`right-wrapper ${this.props.Series[1].id}`}>
            <div className="in">
              <div className="line" style={this.state.lines[1].cssStyles} />
              <div className="bullet" style={this.state.bullets[1].cssStyles} />
              <div className="label" style={this.state.labels[1].cssStyles}>
                <b>{this.state.labels[1].label}%</b>
                {this.props.Series[1].labelAddOn && (
                  <span className="labelAddOn">{this.props.Series[0].labelAddOn}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default LollipopChart;
