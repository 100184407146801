import React from 'react';
import FacesContainer from './containers/FacesContainer';
import './faces.scss';

const Faces = () => {
  return (
    <div className="FacesContainer">
      <div className="component-wrapper">
        <h2>News by news</h2>
        <div className="cols">
          <p>
            Thousands of news outlets covered the General Elections, from the local newspaper
            <span role="img" aria-label="News">
              🗞️
            </span>{' '}
            to the international publications
            <span role="img" aria-label="Satellite">
              📡
            </span>
            .
            <br />
            We tracked and visualized news in real time
            <span role="img" aria-label="Hourglass Not Done">
              ⏳
            </span>{' '}
            from hundreds of sources and for each of them we detected the reported political
            leaders, the coalitions and their level of engagement
            <span style={{whiteSpace: 'nowrap'}}>
              <span className="party-name no-underline">&nbsp;</span>
              <span className="party-name no-underline medium">&nbsp;</span>
              <span className="party-name no-underline large">&nbsp;</span>
            </span>{' '}
            on social networks.
          </p>
          <p>
            We stopped the real-time collection of the news as the elections ended. The following
            view shows the news on 23rd of May, the result day, in which the{' '}
            <span className="party-name nda" style={{borderColor: 'rgb(255, 187, 12)'}}>
              NDA
            </span>{' '}
            and{' '}
            <span
              className="politician-name narendra-modi nda"
              style={{borderColor: 'rgb(255, 187, 12)'}}
            >
              Narendra Modi
            </span>{' '}
            were declared the winners of the elections.
          </p>
        </div>
      </div>
      <div className="chart--wrapper">
        <figure>
          <div className="in">
            <div className="Faces--wrapper">
              <FacesContainer />
            </div>
          </div>
        </figure>
      </div>
    </div>
  );
};

export default Faces;
