import React from 'react';
import DOMPurify from 'dompurify';

import './SimpleAnnotation.scss';

const SimpleAnnotation = props => {
  const {text, left, width, top, lang = 'en', orientation = 'left-to-right'} = props;
  const annotationClass = `annotation ${orientation}`;
  const annotationStyle = {
    left,
    top,
  };

  const pStyle = {};
  if (width) {
    pStyle.width = width;
  }

  return (
    <div className={annotationClass} style={annotationStyle}>
      <p
        className="text"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(text[lang]),
        }}
        style={pStyle}
      />
      <div className="arrow">
        <span />
      </div>
    </div>
  );
};

export default SimpleAnnotation;
