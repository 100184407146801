import React, {useState} from 'react';
import TileMap from '../TileMap';
import Ridgeline from '../Ridgeline/Ridgeline';

import stateInfo from './states.json';
import './map.scss';

const DEFAULT_STATE = 'delhi';

const sampleData = {
  mayawati: [{activity: 1, timestamp: 1551378600}, {activity: 1, timestamp: 1556735400}],
  nda: [{activity: 1, timestamp: 1551378600}, {activity: 1, timestamp: 1556735400}],
  others: [{activity: 1, timestamp: 1551378600}, {activity: 1, timestamp: 1556735400}],
  upa: [{activity: 1, timestamp: 1551378600}, {activity: 1, timestamp: 1556735400}],
};

export default function Map(props) {
  const [indianState, setIndianState] = useState(stateInfo[DEFAULT_STATE]);

  const {data, options, legend, mapLegend} = props;

  const shownState = props.data.find(d => d.state === indianState.name);
  const shownStateOptions = Object.assign({}, options, {
    legend,
    show: 'activity',
  });

  return (
    <div className="interactive-map">
      <div>
        <TileMap
          data={data}
          sampleData={sampleData}
          options={options}
          onClickHandler={stateName => {
            setIndianState(stateInfo[stateName]);
          }}
          mapLegend={mapLegend}
        />
        <span className="note large">
          {props.note}. This map simulates the political map of India showing the states with equal
          size. The Union Territories (apart from Delhi NCT) are half size.
        </span>
      </div>
      <div className="state-description">
        <h2>{indianState.name}</h2>
        <ul>
          <li>
            Population: <b>{indianState.population}</b>
          </li>
          <li>
            {indianState.cmTitle || 'Chief Minister'}: <b>{indianState.cm}</b>
          </li>
          <li>
            Election dates: <b>{indianState.dates}</b>
          </li>
          <li>
            Number of constituencies: <b>{indianState.constituencies}</b>
          </li>
        </ul>
        <h3>News breakdown for {indianState.name}</h3>
        <div className="States--chart" style={{flexGrow: 1}}>
          {shownState && <Ridgeline Series={shownState.timeline} Options={shownStateOptions} />}
        </div>
        <span className="note large">
          This chart shows the amount of news in which the coalitions and {indianState.name} are
          mentioned in the same article.
        </span>
        <p className="note small">
          The map shows the {props.note.toLowerCase()}. It simulates the political map of India
          showing the states with equal size. The Union Territories (apart from Delhi NCT) are half
          size.
          <br />
        </p>
        <p className="note small">
          The chart shows the amount of news in which the coalitions and {indianState.name} are
          mentioned in the same article.
        </p>
      </div>
    </div>
  );
}
