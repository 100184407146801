import React from 'react';
import {range} from 'd3-array';
import Tile from './Tile';
import SimpleAnnotation from '../SimpleAnnotation';

import {prettyNames} from '../../Helpers/utils';

import './tilemap.scss';
import STATES_MAP from './map.json';

const annotations = {
  delhi: {
    id: 'delhi',
    text: {
      en: 'Delhi',
    },
    orientation: 'right-to-left',
    left: 0.95,
    top: -0.001,
  },
  maharashtra: {
    id: 'maharashtra',
    text: {
      en: 'Maharashtra',
    },
    top: 0.7,
    orientation: 'straight left-to-right',
  },
  gujarat: {
    id: 'gujarat',
    text: {
      en: 'Gujarat',
    },
    orientation: 'straight left-to-right',
  },
  'west bengal': {
    id: 'westBengal',
    text: {
      en: 'West Bengal',
    },
    orientation: 'straight bottom-to-top left-aligned',
    top: 1,
    left: 0.5,
  },
  'jammu and kashmir': {
    id: 'jammuAndKashmir',
    text: {
      en: 'Jammu & Kashmir',
    },
    orientation: 'straight left-to-right',
  },
};

const TileMap = props => {
  const {data, options, sampleData, mapLegend, onClickHandler} = props;

  return (
    <div className="tile-map">
      {mapLegend && (
        <div className="tile-key">
          <div className="tile">
            <Tile
              data={sampleData}
              extents={{x: options.x, y: options.y}}
              fields={{x: 'timestamp', y: 'activity'}}
            />
            <ul className="keys">
              {Object.keys(sampleData).map(d => (
                <li key={d}>{prettyNames[d]}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {range(9).map(row => {
        return (
          <div className="row" key={`row${row}`}>
            {range(9).map(col => {
              return (
                <div className="cell" key={`cell${col}-${row}`}>
                  {STATES_MAP.filter(d => d.row + 1 === row && d.col === col).map(indianState => {
                    const tileStyle = {};
                    if (indianState) {
                      tileStyle.width = `${100 * (indianState.width || 1)}%`;
                      tileStyle.height = `${100 * (indianState.height || 1)}%`;
                      tileStyle.left = `${100 * (indianState.colDelta || 0)}%`;
                      tileStyle.top = `${100 * (indianState.rowDelta || 0)}%`;
                    }

                    const selectedIndianState = data.find(
                      d => d.state.toLowerCase() === indianState.state,
                    );

                    return (
                      <div
                        key={indianState.state}
                        rel={indianState.state}
                        className="tile"
                        onMouseDown={() => onClickHandler(indianState.state)}
                        onKeyPress={() => onClickHandler(indianState.state)}
                        role="button"
                        tabIndex={row * 10 + col}
                        style={tileStyle}
                      >
                        <div className="state-name">{indianState.state}</div>
                        <div className="chart-container">
                          {selectedIndianState && (
                            <Tile
                              data={selectedIndianState.timeline}
                              extents={{x: options.x, y: options.y}}
                              fields={{x: 'timestamp', y: 'activity'}}
                            />
                          )}
                          {annotations[indianState.state] && (
                            <SimpleAnnotation
                              {...annotations[indianState.state]}
                              left={
                                annotations[indianState.state].left
                                  ? `${annotations[indianState.state].left * 100}%`
                                  : -4
                              }
                              top={
                                annotations[indianState.state].top
                                  ? `${annotations[indianState.state].top * 100}%`
                                  : `50%`
                              }
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TileMap;
