import React from 'react';
import moment from 'moment-timezone';
import {format} from 'd3-format';
import {sum} from 'd3-array';
import {colors, prettyNames} from '../../../../Helpers/utils';
import './visualtimeline.scss';

const percFormat = d => {
  if (d < 0.01) {
    return format(',.1%')(d);
  }

  return format(',.0%')(d);
};

const VisualTimeline = props => {
  if (!props.slugsSortedList.length) {
    return null;
  }

  const nowInIndia = moment().tz('Asia/Kolkata');
  // const inIndia = new Date().getTimezoneOffset() === -330;
  let prevY = null;
  // console.log('!!!', inIndia, nowInIndia, moment().format('HH:mm z'));
  // let prevY = -10000;

  const texts = props.texts.map((d, textIndex) => {
    const classNames = d.className || '';
    const y = !prevY || d.y - (prevY || 0) > 450 ? d.y : d.y + 450;
    prevY = y;
    // console.log('Y', y);

    if (d.data) {
      const sortedSlugs = Object.entries(d.data).sort((a, b) => b[1] - a[1]);
      // console.log('sortedSlugs', sortedSlugs);
      const coalitions = (
        <span>
          the{' '}
          <span
            className={`party-name ${sortedSlugs[0][0]}`}
            style={{borderColor: colors[sortedSlugs[0][0]]}}
          >
            {prettyNames[sortedSlugs[0][0]]}
          </span>{' '}
          {sortedSlugs[1] && (
            <span>
              followed by the{' '}
              <span
                className={`party-name ${sortedSlugs[1][0]}`}
                style={{borderColor: colors[sortedSlugs[1][0]]}}
              >
                {prettyNames[sortedSlugs[1][0]]}
              </span>
            </span>
          )}
        </span>
      );
      // const mostPopularNews = <span>The most popular</span>;
      const politicians = (
        <span>
          <span
            className={`politician-name ${d.politicians[0].className} ${
              d.politicians[0].coalition
            }`}
            style={{borderColor: colors[d.politicians[0].coalition]}}
          >
            {d.politicians[0].name}
          </span>{' '}
          {!textIndex ? 'is' : 'was'} in <b>{percFormat(d.politicians[0].perc)}</b> of the recorded
          news, followed by{' '}
          <span
            className={`politician-name ${d.politicians[1].className} ${
              d.politicians[1].coalition
            }`}
            style={{borderColor: colors[d.politicians[1].coalition]}}
          >
            {d.politicians[1].name}
          </span>{' '}
          at <b>{percFormat(d.politicians[1].perc)}</b>.
        </span>
      );
      // {d.politicians[1].perc < 0.2 ? 'only' : ''}
      const others = (
        <span>
          {d.politicians
            .filter((p, i) => i > 1)
            .map((politician, i) => {
              return (
                <span key={`others-${politician.className}-${nowInIndia.format('Do MMMM')}`}>
                  <span
                    className={`politician-name ${politician.className} ${politician.coalition}`}
                    style={{borderColor: colors[politician.coalition]}}
                  >
                    {politician.name}
                  </span>
                  {i < d.politicians.length - 4 ? ', ' : ''}
                  {i === d.politicians.length - 4 ? ' and ' : ''}
                </span>
              );
            })}{' '}
          together are in{' '}
          <b>{percFormat(sum(d.politicians.filter((p, i) => i > 1), p => p.perc))}</b> of the news.
        </span>
      );

      // if (!inIndia && !textIndex) {
      //   return (
      //     <li key={d.id} className={classNames} style={{top: `${y}px`}}>
      //       {!inIndia && (
      //         <span>
      //           In India it's <b>{nowInIndia.format('h:mma')}</b> on{' '}
      //           <b>{nowInIndia.format('Do MMMM')}</b> and we have{' '}
      //           {+nowInIndia.format('HH') <= 12 ? 'already' : ''} recorded <b>{d.total}</b>{' '}
      //           <span className="party-name no-underline">news</span>{' '}
      //           {inIndia ? 'so far today' : 'during the day'}.
      //         </span>
      //       )}
      //       <span>The most reported coalition has been {coalitions}.</span>
      //       {d.total > 50 && (
      //         <p>
      //           {politicians} {others}
      //         </p>
      //       )}
      //     </li>
      //   );
      // }

      // return (
      //   <li key={d.id} className={classNames} style={{top: `${y}px`}}>
      //     <span>
      //       On <b>{moment(d.date).format('Do MMMM')}</b> we recorded <b>{d.total}</b>{' '}
      //       <span className="party-name no-underline">news</span>.
      //     </span>
      //     <span>The most reported coalition was {coalitions}.</span>
      //     <p>
      //       {politicians} {others}
      //     </p>
      //   </li>
      // );

      return (
        <li key={d.id} className={classNames} style={{top: `${y}px`}}>
          <span>
            On <b>{moment(d.date).format('Do MMMM')}</b> we recorded <b>{d.total}</b>{' '}
            <span className="party-name no-underline">news</span>.
          </span>
          <span>The most reported coalition was {coalitions}.</span>
          <p>
            {politicians} {others}
          </p>
        </li>
      );
    }

    return (
      <li key={d.id} style={{top: `${y}px`}} className={classNames}>
        <span dangerouslySetInnerHTML={{__html: d.text}} />
      </li>
    );
  });

  return (
    <section id="visualTimeline" className="contents-overlay parallax-layer parallax-layer-back">
      <div className="component-wrapper">
        <ul className="visual-timeline">{texts}</ul>
      </div>
    </section>
  );
};

// const DiamondsChart = props => {
//   let diamonds = [];
//   Object.entries(props.data).forEach((slug, i) => {
//     diamonds = [
//       ...diamonds,
//       ...range(slug[1]).map((d, i) => ({
//         index: `${diamonds.length}_${i}`,
//         slug: slug[0]
//       }))
//     ].sort((a, b) => areas.indexOf(a.slug) - areas.indexOf(b.slug));
//   });
//   return (
//     <ul className="diamond-chart">
//       {diamonds.map((d, i) => (
//         <li key={d.index} style={{ backgroundColor: colors[d.slug] }}>
//           {d.slug}
//         </li>
//       ))}
//     </ul>
//   );
// };

export default VisualTimeline;
