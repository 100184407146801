import React, {Component} from 'react';
import axios from 'axios';
import InteractiveMap from '../InteractiveMap';
import {prettyNames} from '../../Helpers/utils';

import './States.css';

class States extends Component {
  constructor(props) {
    super(props);
    this.endpoint_politicians = '/data/politicians-and-states.json'; // 'https://india.elezioni.io/v0/articles/politicians-and-states/';
    this.endpoint_coalitions = '/data/coalitions-and-states.json'; // 'https://india.elezioni.io/v0/articles/coalitions-and-states';
    this.container = React.createRef();
    this.load = this.load.bind(this);

    this.showAllPoliticians = this.showAllPoliticians.bind(this);
    this.showAllCoalitions = this.showAllCoalitions.bind(this);

    this.state = {
      data_politicians: [],
      data_coalitions: [],
      legend_politicians: {
        modi: 'Narendra Modi',
        gandhi: 'Rahul Gandhi',
        mayawati: 'Mayawati',
        yadav: 'Akilesh Yadav',
        banerjee: 'Mamata Banerjee',
        yogi: 'Yogi Adityanath',
        kejriwal: 'Arvind Kejriwal',
      },
      legend_coalitions: {
        nda: prettyNames.nda,
        upa: prettyNames.upa,
        mayawati: prettyNames.mahagathbandhan,
        others: prettyNames.others,
      },
      loading: true,
      message_politicians: 'Expand',
      message_coalitions: 'Expand',
      options_politicians: {},
      options_coalitions: {},
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    axios
      .get(this.endpoint_politicians, {withCredentials: false})
      .then(response => {
        if (response.status === 200 && !response.data.error) {
          const options_politicians = {
            x: {
              min: null,
              max: null,
            },
            y: {
              min: null,
              max: null,
            },
          };
          this.setState({data_politicians: response.data.data, options_politicians});
        } else {
          console.log(`An error occourred ${response.statusText} ${response.data.errormessage}`);
        }
      })
      .catch(error => {
        console.log(`Error ${error}`);
      });

    axios
      .get(this.endpoint_coalitions, {withCredentials: false})
      .then(response => {
        // console.log('2', response);
        if (response.status === 200 && !response.data.error) {
          const options_coalitions = {
            x: {
              min: null,
              max: null,
            },
            y: {
              min: null,
              max: null,
            },
          };
          this.setState({data_coalitions: response.data.data, options_coalitions});
        } else {
          console.log(`An error occourred ${response.statusText} ${response.data.errormessage}`);
        }
      })
      .catch(error => {
        console.log(`Error ${error}`);
      });
  }

  showAllPoliticians() {
    let message_politicians = 'Expand';
    this.container.current.querySelector('.politicians').classList.toggle('show-all');
    if (this.state.message === 'Collapse') {
      message_politicians = 'Expand';
    } else {
      message_politicians = 'Collapse';
    }
    this.setState({message_politicians}, () => {
      window.location.href = '#politicians-and-states';
    });
  }

  showAllCoalitions() {
    let message_coalitions = 'Expand';
    this.container.current.querySelector('.coalitions').classList.toggle('show-all');
    if (this.state.message === 'Collapse') {
      message_coalitions = 'Expand';
    } else {
      message_coalitions = 'Collapse';
    }
    this.setState({message_coalitions}, () => {
      window.location.href = '#coalitions-and-states';
    });
  }

  render() {
    return (
      <div className="States" ref={this.container}>
        <div className="component-wrapper" id="politicians-and-states">
          <h2>Coalitions State by State</h2>
          <div className="cols">
            <p>
              The Republic of India
              <span role="img" aria-label="India">
                🇮🇳
              </span>
              consists of 29 states and 7 union territories. The elections
              <span role="img" aria-label="Voting">
                🗳️
              </span>
              took place during several phases in each state between April 11th and May 19th. The
              news
              <span role="img" aria-label="News">
                🗞️
              </span>
              covered stories from different areas of the country. We tracked how frequently the
              coalitions and political leaders were associated with the states in the news.
            </p>
            <p>
              The following map
              <span role="img" aria-label="Map">
                🗺️
              </span>
              shows the share of news for each coalition when it was associated with an Indian
              state. The chart
              <span role="img" aria-label="Chart">
                📈
              </span>
              also shows a more detailed time-based view.
              <br />
              <i>
                Select
                <span role="img" aria-label="Index">
                  👆
                </span>
                a state on the map to consult the breakdown.
              </i>
            </p>
          </div>
        </div>
        <div className="component-wrapper">
          <InteractiveMap
            data={this.state.data_coalitions}
            options={this.state.options_coalitions}
            legend={this.state.legend_coalitions}
            mapLegend
            note="Share of news for each coalition per state"
          />
        </div>
        <div className="component-wrapper" id="coalitions-and-states">
          <h2>Politicians State by State</h2>
          <div className="cols">
            <p>
              The following map
              <span role="img" aria-label="Map">
                🗺️
              </span>
              shows the share of news for each political leader in articles in which their name was
              associated with an Indian state. The chart
              <span role="img" aria-label="Chart">
                📈
              </span>
              shows a more detailed time-based view.
              <br />
              <i>
                Select
                <span role="img" aria-label="Index">
                  👆
                </span>
                a state on the map to consult the breakdown.
              </i>
            </p>
          </div>
        </div>
        <div className="component-wrapper">
          <InteractiveMap
            data={this.state.data_politicians}
            options={this.state.options_politicians}
            legend={this.state.legend_politicians}
            note="Share of news for each political leader"
          />
        </div>
      </div>
    );
  }
}

export default States;
