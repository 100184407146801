import React, {Component} from 'react';
import axios from 'axios';
import 'intersection-observer';
import {sum as d3Sum} from 'd3-array';
import './Challenge.css';
import LollipopChart from '../LollipopChart/LollipopChart';
import modiDrawing from './Assets/modi.png';
import gandhiDrawing from './Assets/gandhi.png';
import modiMask from './Assets/modi_mask.png';
import gandhiMask from './Assets/gandhi_mask.png';

const COALITIONS = ['nda', 'upa', 'mahagathbandhan', 'banerjee'];

class Challenge extends Component {
  constructor(props) {
    super(props);
    this.endpoint = '/data/challenge.json'; // 'https://india.elezioni.io/v0/stats/challenge';

    this.load = this.load.bind(this);
    this.currentScrollTop = 0;
    this.portraitsY = 0;

    this.state = {
      coverage: 0,
      gandhi: {
        activity: 0,
        popularity: 0,
      },
      global: {
        activity: 0,
        popularity: 0,
      },
      loading: true,
      modi: {
        activity: 0,
        popularity: 0,
      },
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    axios
      .get(this.endpoint, {withCredentials: false})
      .then(response => {
        if (response.status === 200 && !response.data.error) {
          // console.log(response.data.data);
          const coalitionsGlobal = d3Sum(COALITIONS, d => response.data.data[d].activity);
          // console.log('coalitionsGlobal',coalitionsGlobal)
          this.setState({
            coverage: Math.round(
              ((parseInt(response.data.data.gandhi.activity, 10) +
                parseInt(response.data.data.modi.activity, 10)) *
                100) /
                parseInt(coalitionsGlobal, 10),
            ),
            gandhi: {
              activity: response.data.data.gandhi.activity,
              popularity: response.data.data.gandhi.popularity,
            },
            global: {
              activity: coalitionsGlobal,
              popularity: response.data.data.global.popularity,
            },
            loading: false,
            modi: {
              activity: response.data.data.modi.activity,
              popularity: response.data.data.modi.popularity,
            },
            nda: {
              activity: response.data.data.nda.activity,
              popularity: response.data.data.nda.popularity,
            },
            upa: {
              activity: response.data.data.upa.activity,
              popularity: response.data.data.upa.popularity,
            },
          });
        } else {
          console.log(`An error occourred ${response.statusText} ${response.data.errormessage}`);
        }
      })
      .catch(error => {
        console.log(`Error ${error}`);
        // console.log(error);
      });
  }

  render() {
    return (
      <div className="Challenge">
        <div
          ref={el => {
            this.portraits = el;
          }}
          className="portraits"
        >
          <div className="in">
            <div className="image modi">
              <figure>
                <img src={modiDrawing} alt="Narendra Modi" />
              </figure>
            </div>

            <div className="image gandhi">
              <figure>
                <img src={gandhiDrawing} alt="Rahul Gandhi" />
              </figure>
            </div>
          </div>
        </div>
        <div
          ref={el => {
            this.textsContainer = el;
          }}
          className="component-wrapper texts"
        >
          <div className="cols intro story">
            <p>
              Nine hundred million{' '}
              <span role="img" aria-label="Astonished">
                😲
              </span>{' '}
              Indians went to the polls
              <span role="img" aria-label="Voting">
                🗳️
              </span>{' '}
              between April 11th and May 19th to elect their representatives in the Lok Sabha,
              India’s{' '}
              <span role="img" aria-label="India">
                🇮🇳
              </span>{' '}
              lower house, for a new government and prime minister.
            </p>
          </div>

          <div className="cols intro story stand-first">
            <p>
              How did the news
              <span role="img" aria-label="News">
                🗞️
              </span>{' '}
              covered these elections? Prime Minister{' '}
              <span className="legend-nda">Narendra Modi</span> was the hero
              <span role="img" aria-label="Superhero">
                🦸‍♂️
              </span>{' '}
              of the news but was his challenger
              <span role="img" aria-label="Superhero">
                🚴
              </span>{' '}
              from Congress, <span className="legend-upa">Rahul Gandhi</span>, able to conquer some
              space?
            </p>
          </div>

          <div className="cols chart--intro story">
            <p>
              We tracked the contents and popularity of news about the coalitions, parties and
              political leaders running for the <b>General Elections 2019</b>. This page puts
              together this immense amount of data to make sense of what the news media{' '}
              <span role="img" aria-label="Newspaper">
                📰
              </span>
              <span role="img" aria-label="TV">
                📺
              </span>
              <span role="img" aria-label="Satellite">
                📡
              </span>{' '}
              delivered to the readers and their reaction.
            </p>
          </div>
        </div>

        <div
          ref={el => {
            this.masks = el;
          }}
          className="masks"
        >
          <div className="in">
            <div className="image modi">
              <figure>
                <img src={modiMask} alt="Narendra Modi" />
              </figure>
            </div>

            <div className="image gandhi">
              <figure>
                <img src={gandhiMask} alt="Rahul Gandhi" />
              </figure>
            </div>
          </div>
        </div>

        <div
          ref={el => {
            this.textsContainer = el;
          }}
          className="component-wrapper texts"
        >
          <h2>Head to head</h2>
          <div className="cols chart--intro story">
            <p>
              There have been more than 2,000 parties contesting the Indian elections. However, it
              could be described as a race{' '}
              <span role="img" aria-label="race">
                🏎️
              </span>
              <span role="img" aria-label="scooter">
                🛵
              </span>{' '}
              between two alliances: the{' '}
              <span className="legend-nda">National Democratic Alliance (NDA)</span> led by Prime
              Minister <b>Narendra Modi</b> and the{' '}
              <span className="legend-upa">United Progressive Alliance (UPA)</span>, led by{' '}
              <b>Rahul Gandhi</b>, president of the <b>Indian National Congress</b>.
            </p>
            <h3>
              <span role="img" aria-label="notes">
                🎶
              </span>
              All we need is… two charts{' '}
              <span role="img" aria-label="note">
                🎵
              </span>
            </h3>
            <p>
              The following charts put in a nutshell the whole story behind the way news media
              <span role="img" aria-label="Newspaper">
                📰
              </span>
              <span role="img" aria-label="TV">
                📺
              </span>
              <span role="img" aria-label="Satellite">
                📡
              </span>
              delivered this head to head to the readers.
            </p>
            <p>
              The first chart shows the percentage of news that covered the General Elections 2019
              mentioning the <span className="legend-nda">NDA</span> or the{' '}
              <span className="legend-upa">UPA</span>.
            </p>
          </div>

          <div className="chart-wrapper activity story">
            <LollipopChart
              Data={this.state}
              Plot="activity"
              Series={[
                {id: 'nda', label: 'NDA', showLegend: true, labelAddOn: 'of news'},
                {id: 'upa', label: 'UPA', showLegend: true, labelAddOn: 'of news'},
              ]}
            />
          </div>
          <div className="cols chart--intro story">
            <p>
              The second chart compares the percentage of news mentioning the Prime Minister{' '}
              <span className="legend-nda">Narendra Modi</span> or{' '}
              <span className="legend-upa">Rahul Gandhi</span>.
            </p>
          </div>
          <div className="chart-wrapper activity story">
            <LollipopChart
              Data={this.state}
              Plot="activity"
              Series={[
                {id: 'modi', label: 'N. Modi', showLegend: true, labelAddOn: 'of news'},
                {id: 'gandhi', label: 'R. Gandhi', showLegend: true, labelAddOn: 'of news'},
              ]}
            />
          </div>
          <div className="cols chart--intro story">
            <p>
              Luckily there are nuances hidden in every single piece of news that made this more
              interesting than what it might look like{' '}
              <span role="img" aria-label="Face With Sweat">
                😅
              </span>
              . <strong>All the data refers to the period between March 1st and June 1st</strong>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Challenge;
