import {range, mean, deviation} from 'd3-array';
import {radialLine, curveCardinalClosed as curve} from 'd3-shape';

export const areas = ['destra', 'centro-destra', 'm5s', 'centro-sinistra', 'sinistra'];
export const wordArticles = {
  destra: 'la',
  'centro-destra': 'il',
  m5s: 'il',
  'centro-sinistra': 'il',
  sinistra: 'la',
};
export const prettyNames = {
  destra: 'Destra',
  'centro-destra': 'Centro Destra',
  m5s: 'Movimento 5 Stelle',
  'centro-sinistra': 'Centro Sinistra',
  sinistra: 'Sinistra',
  nda: 'NDA',
  upa: 'UPA',
  mahagathbandhan: 'Mahagathbandhan',
  mayawati: 'Mahagathbandhan',
  others: 'Others',
};
export const colors = {
  destra: '#244761',
  'centro-destra': '#56CCF2',
  'centro-sinistra': '#F55B5B',
  sinistra: 'rgba(186,0,78,1)',
  m5s: '#FFDC73',
  black: 'rgba(0,0,0,0)',
  lega: '#377D22',
  nda: '#ffbb0c',
  upa: '#2f90b1',
  mahagathbandhan: '#66a931',
  'mahagathbandhan-yadav': '#66a931',
  'mahagathbandhan-mayawati': '#66a931',
  others: '#a275ab',
};
export const colorsLow = {
  nda: '#ffd872',
  upa: '#89d3ec',
  mahagathbandhan: '#a8d882',
  'mahagathbandhan-yadav': '#a8d882',
  'mahagathbandhan-mayawati': '#a8d882',
  others: '#d9ade2',
};

const sentencesToBeCleanedUp = [
  '- The Financial Express',
  '- Early Times Newspaper Jammu Kashmir',
  '- The English Post',
  '- Maneoi',
  '- Alt News',
  /\| Asian News Hub/,
  /\| News - Times of India Videos/,
  '- Times of India',
  '- Raman Media Network',
  /\| NewsClick/,
  /\| Politics Informer/,
  '- Hindustan Times',
  '- TNT-The NorthEast Today',
  /\| Latest News & Updates at DNAIndia.com/,
  '- Newsfeed',
  '- Northlines',
  '- Firstpost',
  /\| The Express Tribune/,
  '- WP Status24',
  '- Muslim Mirror',
  /\| KalingaTV/,
  '- Veekeez',
  '- ETCFO',
  '- Opindia News',
  '– Gays For Trump',
  '- Latest News Meri Aawaz',
  '- Times of News India',
  '- THE NEWS',
  /\/ DemocraticAccent/,
  /\| OdishaSunTimes.com/,
  '- NewsX',
  /\| SCC Blog/,
  /\| Indiablooms - First Portal on Digital News Management/,
  /Indiablooms - First Portal on Digital News Management/,
  '- Reporters Today',
  '- JK Breaking News',
  '- DKODING',
  /\| india news/,
  '- JK MONITOR',
  '- Real Report',
  /\| WatsupAsia - Asia's Latest News & Entertainment Platform/,
  /\| New York/,
  '- Republic World',
  '- Caravan Daily',
  '- Kashmir Age',
  '- Punjab Update',
  '- Indian Defence News',
  '- NewsCentral24x7',
  '- ManaTeluguMovies.net',
  /- Janta Ka Reporter 2\.0/,
  '- Muslim Mirror',
  '- WP Status24',
  '- Modi Hai To Mumkin Hai',
  "- New Delhi Times - India's Only International Newspaper",
  /\| HW English/,
  '- The Indian Wire/',
  '· Telanganaheadlines.in',
  '- India Times of News',
  '►',
];
export const cleanUp = sentence => {
  let cleanSentence = sentence;
  sentencesToBeCleanedUp.forEach(cleanUpString => {
    const re = new RegExp(cleanUpString, 'gi');
    cleanSentence = cleanSentence.replace(re, '');
  });

  return cleanSentence;
};

const arc = (sides = 4) => (2 * Math.PI) / sides;
export const SQRT2 = Math.sqrt(2);
export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;

export const radialArea = radialLine().curve(curve.tension(0.95));

export const getCoords = (side, sides = 4, margin = 0) => {
  const r = (side - margin) * SQRT2;
  const points = range(sides).map(function(d) {
    return [d * arc(sides), r / 2];
  });

  return points;
};

export const getConnectingLine = (x1, y1, x2, y2) => {
  const l = x1 - x2;
  const h = y1 - y2;

  const d = Math.sqrt(l * l + h * h);
  const rad = Math.atan2(h, l);
  const deg = rad * RAD2DEG;
  const offset = {
    y: y1,
    x: x1,
  };

  return {
    top: `${offset.y}px`,
    left: `${offset.x}px`,
    width: `${d}px`,
    transform: `rotate(${deg - 180}deg)`,
  };
};

export const calculatePopularity = article => {
  const {
    // popularity_updated,
    // popularity_logs,
    tw_favorites,
    // tw_reach,
    tw_retweets,
    tw_tweets,
    fb_shares,
    fb_comments,
    fb_reactions,
  } = article;

  const T = +tw_tweets;
  const Tr = +tw_retweets / T;
  const Tl = +tw_favorites / T;
  const Tf = 0; // +tw_reach / T;

  const F = +fb_shares;
  const Fc = F > 0 ? +fb_comments / F : 0;
  const Fr = F > 0 ? +fb_reactions / F : 0;

  // return (T / (popularity_updated + 1) + Tr + Tl + Tf + F / (popularity_updated + 1) + Fc + Fr);
  return Tr + Tl + Tf + Fc + Fr; // / (popularity_updated + 1);
};

export const TAGS = {
  nda: [
    'bjp',
    'narendra modi',
    'nda',
    'modi',
    'aiadmk',
    'shiv sena',
    'rahul gandhi modi',
    'smriti irani',
    'yogi adityanath',
  ],
  upa: ['congress', 'cong', 'rahul gandhi', 'rahul gandhi modi', 'priyanka gandhi', 'upa'],
  'mahagathbandhan-mayawati': ['mayawati', 'Bahujan Samaj Party', 'BSP'],
  'mahagathbandhan-yadav': ['akhilesh yadav', 'samajwadi', 'samajwadi party', 'abu azmi'],
  others: ['mamata banerjee', 'mamata', 'tmc', 'aitc', 'aap'],
};

export const SLUGS_MAP = {
  'Narendra Modi': 'nda',
  'Yogi Adityanath': 'nda',
  'Rahul Gandhi': 'upa',
  Mayawati: 'mahagathbandhan',
  'Akhilesh Yadav': 'mahagathbandhan',
  'Mamata Banerjee': 'others',
  'mahagathbandhan-mayawati': 'mahagathbandhan',
  'mahagathbandhan-yadav': 'mahagathbandhan',
  bjp: 'nda',
  bsp: 'mahagathbandhan',
  'Bahujan Samaj Party': 'mahagathbandhan',
  congress: 'upa',
  'All India Trinamool Congress': 'others',
  aitc: 'others',
  shivsena: 'shiv sena',
  aap: 'Aam Aadmi Party',
};

export const PARTIES = {
  nda: 'nda',
  upa: 'upa',
  'mahagathbandhan-mayawati': 'mahagathbandhan',
  'mahagathbandhan-yadav': 'mahagathbandhan',
  'All India Trinamool Congress': 'others',
  aitc: 'others',
  tmc: 'others',
  aap: 'others',
};

export const POLITICIANS = {
  'Narendra Modi': ['narendra modi'],
  'Rahul Gandhi': ['rahul gandhi'],
  Mayawati: ['mayawati'],
  'Akhilesh Yadav': ['akhilesh yadav'],
  'Mamata Banerjee': ['mamata banerjee', 'mamata'],
  'Yogi Adityanath': ['yogi adityanath'],
  'Arvind Kejriwal': ['arvind kejriwal'],
};
export const POLITICIANS_MAP = {
  'narendra-modi': 'Narendra Modi',
  'rahul-gandhi': 'Rahul Gandhi',
  'akhilesh-yadav': 'Akhilesh Yadav',
  'mamata-banerjee': 'Mamata Banerjee',
  mayawati: 'Mayawati',
  'yogi-adityanath': 'Yogi Adityanath',
  'arvind-kejriwal': 'Arvind Kejriwal',
};
export const PARTY_NAMES = {
  BJP: ['bjp'],
  congress: ['congress'],
  AITC: ['aitc', 'all india trinamool congress'],
  shivsena: ['shiv sena'],
  bsp: ['bahujan samaj party'],
  sp: ['samajwadi party'],
  aap: ['aam aadmi party'],
};

export const computeZScore = (values, accessor) => {
  const m = mean(values, accessor);
  // here the n-1 : http://duramecho.com/Misc/WhyMinusOneInSd.html
  const d = deviation(values, accessor);
  const getInput = accessor || (v => v);

  return values.map(obj => ({
    ...obj,
    zscore: (getInput(obj) - m) / d,
  }));
};

export const getRetinaRatio = () => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const c = document.createElement('canvas').getContext('2d');
  const backingStoreRatio = [
    c.webkitBackingStorePixelRatio,
    c.mozBackingStorePixelRatio,
    c.msBackingStorePixelRatio,
    c.oBackingStorePixelRatio,
    c.backingStorePixelRatio,
    1,
  ].reduce((a, b) => a || b);

  return devicePixelRatio / backingStoreRatio;
};

export const offset = el => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
};
export const stringFromArray = words => {
  return words.length > 1 ? `${words.slice(0, -1).join(', ')} and ${words.pop()}` : words[0];
};
export const getAnnotationText = (d, textAnnotations, textAnnotationsMap) => {
  let annotationText = null;
  const slugsText = stringFromArray(d.slugs.map(slug => (SLUGS_MAP[slug] || slug).toUpperCase()));
  const showFace = d.diag > 18 * 2;
  if (d.article.politicians.length === 1 && showFace) {
    annotationText = `News about ${POLITICIANS_MAP[d.article.politicians[0]]}`;
    if (d.slugs.length > 1) {
      if (!textAnnotations.onePoliticianMoreCoalitions) {
        annotationText = `${annotationText} ${` with ${slugsText}`}`;
        textAnnotations.onePoliticianMoreCoalitions = annotationText;
        textAnnotationsMap[d.article.id] = annotationText;
      }
    } else if (!textAnnotations.onePolitician) {
      textAnnotations.onePolitician = annotationText;
      textAnnotationsMap[d.article.id] = annotationText;
    }
  } else if (d.article.politicians.length > 1 && showFace) {
    if (!textAnnotations.morePoliticians) {
      annotationText = `News about ${stringFromArray(
        d.article.politicians.map(p => POLITICIANS_MAP[p]),
      )}`;
      textAnnotations.morePoliticians = annotationText;
      textAnnotationsMap[d.article.id] = annotationText;
    }
  } else if (d.article.politicians.length === 0 && d.article.parties.length === 1 && showFace) {
    annotationText = `News about ${d.article.parties[0].toUpperCase()}`;
    if (d.slugs.length > 1) {
      if (!textAnnotations.onePartyMoreCoalitions) {
        annotationText = `${annotationText} ${` with ${slugsText}`}`;
        textAnnotationsMap[d.article.id] = annotationText;
      }
    } else if (!textAnnotations.oneParty) {
      textAnnotations.oneParty = annotationText;
      textAnnotationsMap[d.article.id] = annotationText;
    }
  } else if (d.article.parties.length > 1 && showFace) {
    if (!textAnnotations.moreParties) {
      annotationText = `News about ${stringFromArray(d.article.parties.map(p => p.toUpperCase()))}`;
      textAnnotations.moreParties = annotationText;
      textAnnotationsMap[d.article.id] = annotationText;
    }
  } else {
    annotationText = `News about ${slugsText}`;
    if (!textAnnotations.onlyCoalitions && d.slugs.length === 1) {
      textAnnotations.onlyCoalitions = annotationText;
      textAnnotationsMap[d.article.id] = annotationText;
    }
    if (!textAnnotations.moreCoalitions && d.slugs.length > 1) {
      textAnnotations.moreCoalitions = annotationText;
      textAnnotationsMap[d.article.id] = annotationText;
    }
  }

  return annotationText;
};
