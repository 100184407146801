import React, {Component} from 'react';
import './India.css';

import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Method from './Components/Method/Method';

import Challenge from './Components/Challenge/Challenge';
import States from './Components/States/States';

import OverviewStreamGraph from './Components/OverviewStreamGraph/OverviewStreamGraph';
import CompareCandidates from './Components/CompareCandidates/CompareCandidates';
import DayByDayPlot from './Components/DayByDayPlot';
import Social from './Components/Social';
import Starring from './Components/Starring';
// import NewsOutletsPlot from "./Components/NewsOutletsPlot";
import Faces from './Components/Faces';

class India extends Component {
  render() {
    return (
      <div className="India">
        <Header />

        <Challenge />

        <Social status="intro" />

        <OverviewStreamGraph />

        <Faces />

        <DayByDayPlot />

        <CompareCandidates />

        <States />

        <Starring />

        <Method />

        <Social />

        <Footer />
      </div>
    );
  }
}

export default India;
