import React from 'react';
import './Method.css';

const Method = () => {
  return (
    <div className="Method">
      <div className="component-wrapper">
        <h2>Data and Methods</h2>
        <div className="cols">
          <p>
            The data is collected and analyzed by a set of web-bots{' '}
            <span role="img" aria-label="robot">
              🤖
            </span>{' '}
            and scrapers. The collection and validation of the data, its analysis, and Social
            Network monitoring are all asynchronous tasks: while one bot searches for new articles{' '}
            <span role="img" aria-label="news">
              📰
            </span>{' '}
            and validates them, another one analyses the queue of already-validated news{' '}
            <span role="img" aria-label="news">
              🗞
            </span>
            , and a third one, for each valid article, monitors Facebook and Twitter at regular
            intervals to check their popularity. Other bots are used to clean{' '}
            <span role="img" aria-label="broom">
              🧹
            </span>{' '}
            and normalize the data to allow some real-time{' '}
            <span role="img" aria-label="chronographer">
              ⏱
            </span>{' '}
            analysis{' '}
            <span role="img" aria-label="chart">
              📈
            </span>
            .
          </p>
          <p>
            We use a Natural Language Processor to parse the texts, and assign to each one of the
            stories a set of tags that, depending on the content of the news is written, might
            contain the names of one or more <em>politicians</em>{' '}
            <span role="img" aria-label="person">
              👳
            </span>
            , the names of one or more <em>political parties and coalitions</em>{' '}
            <span role="img" aria-label="flag">
              🚩
            </span>
            , and the names of <em>geographical entities</em> such as States or Cities{' '}
            <span role="img" aria-label="flag">
              🇮🇳
            </span>
            . All our analyses are based on the tags extracted with this method.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Method;
