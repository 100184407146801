import React, {Component} from 'react';
import moment from 'moment';
import SimpleAnnotation from '../../../SimpleAnnotation';
import {SLUGS_MAP, getAnnotationText} from '../../../../Helpers/utils';
import './articletexts.scss';

const MAX_SQUARE_WIDTH_FOR_TITLES = 200;
const BREAKPOINT = 576;
class ArticleTexts extends Component {
  constructor(props) {
    super(props);
    this.selected = null;
  }

  showText(id) {
    this.hideText(this.selected);
    this.selected = id;
    this.ul.querySelector(`li#a${id}`).classList.add('highlight-article');
  }

  hideText(id) {
    // if(id == undefined) {
    if (typeof id === 'undefined' || id === null) {
      return;
    }
    this.ul.querySelector(`li#a${id}`).classList.remove('highlight-article');
  }

  render() {
    const {width, articles} = this.props;

    const textAnnotations = {
      lastIndex: 0,
    };
    const textAnnotationsMap = {};

    const texts = articles.map((d, articleIndex) => {
      const liStyle = {
        top: `${d.position[1] + (width < BREAKPOINT ? d.diag / 2 : 0)}px`,
      };

      let visibilityClassName = d.diag < MAX_SQUARE_WIDTH_FOR_TITLES ? 'hidden' : 'default';

      const x = d.position[0];
      const alignment = x > 0 ? 'right' : 'left';
      if (width > BREAKPOINT) {
        if (x > 0) {
          liStyle.left = x - d.diag / 2;
        } else {
          liStyle.left = x + d.diag / 2;
        }
        liStyle.left = `${Math.round(liStyle.left)}px`;
      }

      visibilityClassName = 'hidden';

      const slugs = d.slugs
        .map(slug => SLUGS_MAP[slug] || slug)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(slug => {
          return (
            <li key={slug} className={slug}>
              {slug}
            </li>
          );
        });

      const annotationText =
        articleIndex > 1 &&
        (!textAnnotations.lastIndex || articleIndex - textAnnotations.lastIndex > 2)
          ? getAnnotationText(d, textAnnotations, textAnnotationsMap)
          : null;
      textAnnotations.lastIndex = annotationText ? articleIndex : textAnnotations.lastIndex;

      let annotationLeft = `calc(50% + ${x - (d.diag / 2) * (alignment === 'right' ? -1 : 1)}px )`;
      let annotationTop = `${-(d.diag / 2 - 5)}px`;
      if (width > BREAKPOINT) {
        annotationLeft = alignment === 'right' ? `calc(100% + ${d.diag}px)` : `${-d.diag}px`;
        annotationTop = '50%';
      }

      return (
        <li
          key={d.article.id}
          id={`a${d.article.id}`}
          style={liStyle}
          className={`article-text ${alignment}-align ${visibilityClassName}`}
        >
          <article>
            <span className="article-date">
              {moment(d.article.date_published).format('h:mma - Do MMM')}
            </span>
            <span className="article-source">{d.article.source}</span>
            <h2>
              <a href={d.article.url} title="Read news" target="_blank" rel="noopener noreferrer">
                {d.article.title}
              </a>
            </h2>
            <ul className="article-slugs">{slugs}</ul>
            <a href={d.article.url} target="_blank" rel="noopener noreferrer" className="read-more">
              Read &raquo;
            </a>
          </article>
          {textAnnotationsMap[d.article.id] && (
            <SimpleAnnotation
              {...{
                key: `annotation-${d.article.id}`,
                left: annotationLeft,
                top: annotationTop,
                // width: "100px",
                orientation: `straight ${
                  alignment === 'right' ? 'right-to-left' : 'left-to-right'
                }`,
                id: d.article.id,
                text: {
                  en: annotationText,
                },
              }}
            />
          )}
        </li>
      );
      // <span className="article-source">{d.article.slugs.join(',')}</span>
      // <span className="article-source">{d.article.popularity}</span>
      // <span className="article-source">{d.article.tags.join(',')}</span>
    });

    return (
      <ul
        ref={el => {
          this.ul = el;
        }}
        className="article-texts"
      >
        {texts}
      </ul>
    );
  }
}

export default ArticleTexts;
