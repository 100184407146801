import React, {Component} from 'react';
import moment from 'moment';
import './topnews.scss';

class TopNews extends Component {
  state = {
    data: null,
    storyNews: [],
    ids: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    const ids = nextProps.data.map(d => d.id).join(',');
    // console.log('IDS', ids, prevState)
    if (ids !== prevState.ids) {
      return {
        data: null,
        ids,
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this._loadAsyncData(this.props.data, this.props.id);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate',this.state.ids,' !== ',prevState.ids, this.state.ids !== prevState.ids);
    // const ids = this.props.data.map(d => d.id).join(',');
    if (this.state.ids !== prevState.ids) {
      this._loadAsyncData(this.props.data, this.props.id);
    }
  }

  _loadAsyncData(data, id) {
    // console.log('TopNews _loadAsyncData', data)
    const idMap = {};
    data.forEach(d => {
      idMap[d.id] = d;
    });
    // const ids = data.map(d => d.id).join(',');
    // fetch(`https://india.elezioni.io/v0/articles/get?ids=${ids}`)
    fetch(`/data/day_by_day/${id}.json`)
      .then(response => {
        return response.json();
      })
      .then(articles => {
        // console.log("TopNews parsed json", articles.data);
        this.setState({
          data: articles.data.map(d => {
            const articleId = idMap[d.id];

            return {
              ...d,
              x: articleId.x,
              y: articleId.y,
            };
          }),
          storyNews: articles.data
            .filter(d => this.props.storyNewsIds.find(news => d.id === news.id))
            .map(d => {
              const storyNews = this.props.storyNewsIds.find(news => d.id === news.id);

              return {
                ...d,
                storyTitle: storyNews.title,
              };
            }),
        });
      })
      .catch(ex => {
        console.log('parsing failed', ex);
      });
  }

  render() {
    const {data, storyNews} = this.state;
    const {margins} = this.props;

    const step = data && data.length ? data[1].x - data[0].x : 0;

    return (
      <div className="top-news">
        <div className="top-news-container">
          {data &&
            data.map(d => {
              const divStyle = {
                left: `${d.x}px`,
                width: `${step}px`,
              };
              const arrowStyle = {
                top: '-5px',
                height: `${Math.floor(d.y)}px`,
              };

              return (
                <div key={d.id} style={divStyle}>
                  <article>{d.title}</article>
                  <span className="top-news-arrow" style={arrowStyle} />
                </div>
              );
            })}
        </div>
        <div
          className="story-news"
          style={{top: `${margins.top}px`, height: `${margins.bottom - margins.top}px`}}
        >
          <div className="story-news-container">
            {storyNews.map(d => {
              return (
                <article key={`top-news-${d.id}`}>
                  <h4>
                    {moment(d.date_published).format('Do MMMM')} - {d.storyTitle}
                  </h4>
                  <h2>{d.title}</h2>
                  <h5>Published on {d.source}</h5>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default TopNews;
