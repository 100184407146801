import React, {Component} from 'react';
import axios from 'axios';

import LineChart from '../LineChart/LineChart';

import './CompareCandidates.css';

class CompareCandidates extends Component {
  constructor(props) {
    super(props);

    this.endpoint = '/data/candidates.json'; // 'https://india.elezioni.io/v0/stats/candidates';

    this.load = this.load.bind(this);

    // SELECT title, description, CONCAT( 'https://india.elezioni.io/click?u=', TO_BASE64( url ) ) AS url, popularity, UNIX_TIMESTAMP('2019-04-10 00:00:00'), source FROM `articles` WHERE (title LIKE '%gandhi%' OR description LIKE '%gandhi%' OR tags LIKE '%gandhi%') AND (date_published >= '2019-05-09 00:00:00'  AND date_published < '2019-05-10 00:00:00') order by popularity desc

    this.state = {
      annotations: {
        adityanath: [
          {
            date: '2019-04-15',
            peak: 152,
            timestamp: 1555266600,
            title:
              'Election watch: EC bans Mayawati from campaigning for 2 days, Adityanath for 3 days',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly9zY3JvbGwuaW4vbGF0ZXN0LzkyMDA5Mi9lbGVjdGlvbi13YXRjaC1zcC1ic3AtYWxsaWFuY2Utd2lsbC1zaHV0LXNob3AtYWZ0ZXItcG9sbC1yZXN1bHRzLXNheXMtbW9kaS1pbi1hbGlnYXJo',
            source: 'scroll.in',
          },
        ],
        banerjee: [
          {
            date: '2019-04-24',
            peak: 84,
            timestamp: 1556044200,
            title:
              'General Elections 2019: Bengal BJP Claims Mamata Banerjee Supports PM. Trinamool Says "Doctored Video"',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9sb2stc2FiaGEtZWxlY3Rpb25zLTIwMTktYmVuZ2FsLWJqcC1jbGFpbXMtbWFtYXRhLWJhbmVyamVlLXN1cHBvcnRzLXBtLXRyaW5hbW9vbC1zYXlzLWRvY3RvcmVkLXZpZGVvLTIwMjc2OTA=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-07',
            peak: 92,
            timestamp: 1557167400,
            title: `
"Wanted To Give PM Tight Slap Of Democracy": Mamata Banerjee's Attack`,
            link:
              'https://india.elezioni.io/click/?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9sb2stc2FiaGEtZWxlY3Rpb24tMjAxOS1tYW1hdGEtYmFuZXJqZWVzLWF0dGFjay1vbi1wbS1uYXJlbmRyYS1tb2RpLXNheXMtd2FudGVkLXRvLWdpdmUtcG0tdGlnaHQtc2xhLTIwMzQzNjg=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-09',
            peak: 100,
            timestamp: 1557340200,
            title: `Prove TMC candidates are coal mafias or do 100 squats holding your ears: Mamata tells PM Modi`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cuZG5haW5kaWEuY29tL2luZGlhL3JlcG9ydC1wcm92ZS10bWMtY2FuZGlkYXRlcy1hcmUtY29hbC1tYWZpYXMtb3ItZG8tMTAwLXNxdWF0cy1ob2xkaW5nLXlvdXItZWFycy1tYW1hdGEtdGVsbHMtcG0tbW9kaS0yNzQ3Njg1',
            source: 'www.dnaindia.com',
          },
          {
            date: '2019-05-16',
            peak: 144,
            timestamp: 1557945000,
            title: `PM Baits Mamata Banerjee, Says "Let's See if Didi Allows My Rally"`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9wbS1uYXJlbmRyYS1tb2RpLWJhaXRzLW1hbWF0YS1iYW5lcmplZS1zYXlzLWxldHMtc2VlLWlmLWRpZGktYWxsb3dzLW15LXJhbGx5LTIwMzg1NTk=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-29',
            peak: 123,
            timestamp: 1559068200,
            title: `Message For Mamata Banerjee In "Special Invitees" For PM Modi's Oath`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9hdC1wcmltZS1taW5pc3Rlci1uYXJlbmRyYS1tb2Rpcy1zd2VhcmluZy1pbi1tZXNzYWdlLWZvci1tYW1hdGEtYmFuZXJqZWUtaW4tc3BlY2lhbC1pbnZpdGVlcy0yMDQ0NTk5',
            source: 'www.ndtv.com',
          },
        ],
        gandhi: [
          {
            date: '2019-04-23',
            peak: 226,
            timestamp: 1555957800,
            title: 'Rahul Gandhi Issued Notice By Top Court Over Comments On Rafale Order',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9yYWh1bC1nYW5kaGktaXNzdWVkLW5vdGljZS1ieS1zdXByZW1lLWNvdXJ0LW92ZXItY29tbWVudHMtb24taXRzLXJhZmFsZS1vcmRlci1oZWFyaW5nLW9uLWFwcmlsLTMwLTIwMjcxODQ=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-04-30',
            peak: 248,
            timestamp: 1556562600,
            title: 'Govt’s notice to Rahul Gandhi over complaint on his citizenship: Report',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cuaGluZHVzdGFudGltZXMuY29tL2luZGlhLW5ld3MvcmFodWwtZ2FuZGhpLWlzc3VlZC1ob21lLW1pbmlzdHJ5LW5vdGljZS1vdmVyLWNvbXBsYWludC1vbi1oaXMtY2l0aXplbnNoaXAvc3RvcnktajNsSkFsYkFHd2p1dVBsUngzZDVzTS5odG1s',
            source: 'timesofindia.indiatimes.com',
          },
          {
            date: '2019-05-02',
            peak: 221,
            timestamp: 1556735400,
            title: "Rahul Gandhi's First TV Interview In Election 2019: Full Transcript",
            link:
              'https://india.elezioni.io/click/?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9yYWh1bC1nYW5kaGlzLWZpcnN0LXR2LWludGVydmlldy1pbi1lbGVjdGlvbi0yMDE5LWZ1bGwtdHJhbnNjcmlwdC0yMDMyMTMz',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-09',
            peak: 255,
            timestamp: 1557340200,
            title: `"Not Necessary To Speak Against Rajiv Gandhi": BJP Leader's Retort To PM`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9ianAtbGVhZGVycy1yZXRvcnQtdG8tcG0tbmFyZW5kcmEtbW9kaS1ub3QtbmVjZXNzYXJ5LXRvLXNwZWFrLWFnYWluc3QtcmFqaXYtZ2FuZGhpLTIwMzQ5Mjg=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-28',
            peak: 275,
            timestamp: 1558981800,
            title: `On Rahul Gandhi's Reported Outburst, Team Ashok Gehlot Fights Back`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9vbi1yYWh1bC1nYW5kaGlzLXJlcG9ydGVkLW91dGJ1cnN0LXRlYW0tYXNob2stZ2VobG90LWZpZ2h0cy1iYWNrLTIwNDQzNjQ=',
            source: 'www.ndtv.com',
          },
        ],
        mayawati: [
          {
            date: '2019-04-15',
            peak: 103,
            timestamp: 1555266600,
            title:
              'Election watch: EC bans Mayawati from campaigning for 2 days, Adityanath for 3 days',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly9zY3JvbGwuaW4vbGF0ZXN0LzkyMDA5Mi9lbGVjdGlvbi13YXRjaC1zcC1ic3AtYWxsaWFuY2Utd2lsbC1zaHV0LXNob3AtYWZ0ZXItcG9sbC1yZXN1bHRzLXNheXMtbW9kaS1pbi1hbGlnYXJo',
            source: 'scroll.in',
          },
        ],
        modi: [
          {
            date: '2019-04-01',
            peak: 369,
            timestamp: 1554057000,
            title:
              'PM Modi says Sharad Pawar ‘hit wicket’ by nephew, NCP hits back with LK Advani dig',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cuaGluZHVzdGFudGltZXMuY29tL2xvay1zYWJoYS1lbGVjdGlvbnMvcG0tbW9kaS1zYXlzLXNoYXJhZC1wYXdhci1oaXQtd2lja2V0LWJ5LW5lcGhldy1uY3AtaGl0cy1iYWNrLXdpdGgtYWR2YW5pLWRpZy9zdG9yeS1WUEVkMFk1MDBndjJtWGFOUDRKV2tNLmh0bWw=',
            source: 'www.hindustantimes.com',
          },
          {
            date: '2019-04-10',
            peak: 454,
            timestamp: 1554834600,
            title: 'Censor board clears the way for release of Modi biopic - Times of India',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly90aW1lc29maW5kaWEuaW5kaWF0aW1lcy5jb20vZWxlY3Rpb25zL25ld3MvY2Vuc29yLWJvYXJkLWNsZWFycy10aGUtd2F5LWZvci1yZWxlYXNlLW9mLW1vZGktYmlvcGljL2FydGljbGVzaG93LzY4ODAzMzYwLmNtcw==',
            source: 'timesofindia.indiatimes.com',
          },
          {
            date: '2019-05-02',
            peak: 340,
            timestamp: 1556735400,
            title: 'Court Summons Rahul Gandhi For "Modi Surname Common For Thieves" Remark',
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9ndWphcmF0LWNvdXJ0LXN1bW1vbnMtcmFodWwtZ2FuZGhpLW92ZXItaGlzLW1vZGktc3VybmFtZS1jb21tb24tZm9yLXRoaWV2ZXMtcmVtYXJrLTIwMzIxMzQ=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-06',
            peak: 366,
            timestamp: 1557081000,
            title: `"Tried Talking To Mamata Didi Twice, She Didn't Respond": PM On Cyclone`,
            link:
              'https://india.elezioni.io/click/?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9wbS1uYXJlbmRyYS1tb2RpLXNheXMtaGUtdHJpZWQtdG8tc3BlYWstdG8tbWFtYXRhLWJhbmVyamVlLWFib3V0LWN5Y2xvbmUtZmFuaS1zaXR1YXRpb24tdHdpY2UtYnV0LXNoLTIwMzM1NTM=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-09',
            peak: 362,
            timestamp: 1557340200,
            title: `Prove TMC candidates are coal mafias or do 100 squats holding your ears: Mamata tells PM Modi`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cuZG5haW5kaWEuY29tL2luZGlhL3JlcG9ydC1wcm92ZS10bWMtY2FuZGlkYXRlcy1hcmUtY29hbC1tYWZpYXMtb3ItZG8tMTAwLXNxdWF0cy1ob2xkaW5nLXlvdXItZWFycy1tYW1hdGEtdGVsbHMtcG0tbW9kaS0yNzQ3Njg1',
            source: 'www.dnaindia.com',
          },
          {
            date: '2019-05-20',
            peak: 392,
            timestamp: 1558290600,
            title: `Exit polls predict another huge Modi win`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly90aW1lc29maW5kaWEuaW5kaWF0aW1lcy5jb20vZWxlY3Rpb25zL25ld3MvZXhpdC1wb2xscy1wcmVkaWN0LWFub3RoZXItaHVnZS1tb2RpLXdpbi9hcnRpY2xlc2hvdy82OTQwNDE4Mi5jbXM=',
            source: 'timesofindia.indiatimes.com',
          },
          {
            date: '2019-05-24',
            peak: 605,
            timestamp: 1558636200,
            title: `Election Result: Prime Minister's Mother Greets Supporters To Chants Of "Har Har Modi"`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9lbGVjdGlvbi1yZXN1bHQtcHJpbWUtbWluaXN0ZXItbmFyZW5kcmEtbW9kaXMtbW90aGVyLWhlZXJhYmVuLWdyZWV0cy1ianAtc3VwcG9ydGVycy1vdXRzaWRlLWhlci1ob21lLTIwNDE3Mjk=',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-29',
            peak: 575,
            timestamp: 1559068200,
            title: `Message For Mamata Banerjee In "Special Invitees" For PM Modi's Oath`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly93d3cubmR0di5jb20vaW5kaWEtbmV3cy9hdC1wcmltZS1taW5pc3Rlci1uYXJlbmRyYS1tb2Rpcy1zd2VhcmluZy1pbi1tZXNzYWdlLWZvci1tYW1hdGEtYmFuZXJqZWUtaW4tc3BlY2lhbC1pbnZpdGVlcy0yMDQ0NTk5',
            source: 'www.ndtv.com',
          },
          {
            date: '2019-05-31',
            peak: 552,
            timestamp: 1559241000,
            title: `Modi Cabinet Live updates: New Cabinet Ministers of India portfolios shortly`,
            link:
              'https://india.elezioni.io/click?u=aHR0cHM6Ly90aW1lc29maW5kaWEuaW5kaWF0aW1lcy5jb20vaW5kaWEvbG9rLXNhYmhhLWVsZWN0aW9ucy0yMDE5LXJlc3VsdHMtbGl2ZS11cGRhdGVzLXBvcnRmb2xpby1kaXN0cmlidXRpb24vbGl2ZWJsb2cvNjk1OTA1NjAuY21z',
            source: 'timesofindia.indiatimes.com',
          },
        ],
      },
      candidates: [],
      loading: true,
      max: {
        activity: 0,
        day: 0,
        popularity: 0,
      },
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    axios
      .get(this.endpoint, {withCredentials: false})
      .then(response => {
        if (response.status === 200 && !response.data.error) {
          const max = {
            activity: Number.MIN_SAFE_INTEGER,
            popularity: Number.MIN_SAFE_INTEGER,
            day: Number.MIN_SAFE_INTEGER,
          };

          // find max
          response.data.data.forEach(candidate => {
            candidate.timeline.forEach(day => {
              if (day.timestamp > max.day) {
                max.day = day.timestamp;
              }
              if (day.activity > max.activity) {
                max.activity = day.activity;
              }
              if (day.popularity > max.popularity) {
                max.popularity = day.popularity;
              }
            });
          });

          this.setState({
            candidates: response.data.data,
            loading: false,
            max,
          });
        } else {
          console.log(`An error occourred ${response.statusText} ${response.data.errormessage}`);
        }
      })
      .catch(error => {
        console.log(`Error ${error}`);
      });
  }

  render() {
    const candidates = this.state.candidates;
    const max = this.state.max;

    return (
      <div className="CompareCandidates">
        <div className="component-wrapper">
          <h2>Candidates</h2>
          <div className="cols">
            <p>
              Every day the press did its best to follow the events of this long campaign and while
              Narendra Modi was leading the crowd, we can see how in limited circumstances the gap
              was reduced and other leaders went under the spotlight.
            </p>
            <p>
              The following grid of charts shows how the press coverage - as the number of articles
              mentioning the main political leaders - evolved over time.
            </p>
          </div>
        </div>
        <div className="CompareCandidates--charts">
          {this.state.candidates.map((candidate, index) => {
            const options = {
              index,
              max,
              show: 'activity',
            };

            return (
              <div
                key={candidate.name}
                className={`CompareCandidates--candidate ${candidate.className}`}
              >
                <h3>{candidate.name}</h3>
                <figure className="CompareCandidates--chart">
                  <LineChart
                    Series={candidates}
                    Options={options}
                    Annotations={this.state.annotations[candidate.className] || null}
                  />
                </figure>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CompareCandidates;
