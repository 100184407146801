import React from 'react';
import './starring.scss';

import {
  Face,
  ModiFaceDef,
  RahulFaceDef,
  MayawatiFaceDef,
  YadavFaceDef,
  MamataFaceDef,
  YogiFaceDef,
  BJPSymbolDef,
  CongressSymbolDef,
  AITCSymbolDef,
  ShivSenaSymbolDef,
  BSPSymbolDef,
  SPSymbolDef,
} from '../Faces/components/Bubbles/faces';

const characters = [
  {
    name: 'PM Narendra Modi',
    img: 'ModiFace',
    defs: <ModiFaceDef />,
    text:
      'The Prime Minister of India, head of the the right-wing, Hindu nationalist, Bharatiya Janata Party (BJP), front runner of these elections. Will he be able to repeat the astonoshing victory of 2014?',
  },
  {
    name: 'Rahul Gandhi',
    img: 'RahulFace',
    defs: <RahulFaceDef />,
    text:
      'Congress party president. He hails from a long line of politicians, known as the Nehru-Gandhi family, which has occupied a prominent place in the politics of India ever since the country gained independence in 1947.',
  },
  {
    name: 'Mayawati',
    img: 'MayawatiFace',
    defs: <MayawatiFaceDef />,
    text:
      'National president of the Bahujan Samaj Party (BSP), which focuses on a platform of social change for Bahujans, more commonly known as Other Backward Classes, Muslim, Scheduled Castes and Scheduled Tribes.',
  },
  {
    name: 'Akhilesh Yadav',
    img: 'YadavFace',
    defs: <YadavFaceDef />,
    text:
      'President of the Samajwadi Party. He served as the Chief Minister of Uttar Pradesh from 2012 to 2017. Having assumed office on 15 March 2012 at the age of 38, he is the youngest person to have held the office. His first significant success in politics was his election as a member of the Lok Sabha for the Kannauj constituency.',
  },
  {
    name: 'Mamata Banerjee',
    img: 'MamataFace',
    defs: <MamataFaceDef />,
    text:
      'Chief Minister of West Bengal since 2011. She is the first woman to hold the office. She founded the party All India Trinamool Congress in 1998 after separating from the Indian National Congress, and became its chairperson. She is often referred to as Didi - elder sister in Hindi and Bengali.',
  },
  {
    name: 'Yogi Adityanath',
    img: 'YogiFace',
    defs: <YogiFaceDef />,
    text:
      'The Monk Who Became Chief Minister. Indian monk and Hindu nationalist politician who is the current Chief Minister of Uttar Pradesh. He was appointed as the Chief Minister after the BJP won the 2017 State Assembly elections, in which he was a prominent campaigner. He is also the founder of the Hindu Yuva Vahini, a youth organization that has been involved in communal violence. He has an image as a right-wing populist Hindutva firebrand.',
  },
  {
    name: 'Bharatiya Janata Party',
    img: 'BJPSymbol',
    defs: <BJPSymbolDef />,
    text:
      "As of 2018, the country's largest political party in terms of representation in the national parliament and state assemblies, and it is the world's largest party in terms of primary membership. BJP is a right-wing party, and its policy has historically reflected Hindu nationalist positions.",
  },
  {
    name: 'Indian National Congress',
    img: 'CongressSymbol',
    defs: <CongressSymbolDef />,
    text:
      'Congress is a secular party whose social democratic platform is generally considered to be on the centre-left of Indian politics. Founded in 1885, it was the first modern nationalist movement to emerge in the British Empire in Asia and Africa. From the late 19th century, and especially after 1920, under the leadership of Mahatma Gandhi, Congress became the principal leader of the Indian independence movement. Congress led India to independence from Great Britain.',
  },
  {
    name: 'Bahujan Samaj Party',
    img: 'BSPSymbol',
    defs: <BSPSymbolDef />,
    text:
      'By vote share in the 2014 general election, it is India\'s third-largest national party, though it did not win any seats in the Lok Sabha. It was formed to represent Bahujans (literally "people in majority"), referring to Scheduled Castes, Scheduled Tribes and Other Backward Castes, along with religious minorities.',
  },
  {
    name: 'Samajwadi Party',
    img: 'SPSymbol',
    defs: <SPSymbolDef />,
    text:
      'One of several parties that emerged when the Janata Dal fragmented into several regional parties. The Samajwadi Party is primarily based in Uttar Pradesh State. It has contested Lok Sabha and State Assembly elections around the country, though its successes have been mainly in Uttar Pradesh.',
  },
  {
    name: 'All India Trinamool Congress',
    img: 'AITCSymbol',
    defs: <AITCSymbolDef />,
    text:
      'National level political party, founded in 1998, the party is led by its founder Mamata Banerjee, who is the current chief minister of West Bengal. Following the 2014 general election, it is currently the fourth largest party in the Lok Sabha with 36 seats.',
  },
  {
    name: 'Shiv Sena',
    img: 'ShivSenaSymbol',
    defs: <ShivSenaSymbolDef />,
    text:
      'Often referred to as an "extremist", "chauvinist", as well as a "fascist party" it is part of the BJP government in Maharashtra since December 2014. It has been a coalition partner in the National Democratic Alliance since 1998.',
  },
];
const width = 100;
const height = width;
const Starring = () => {
  return (
    <div className="starring">
      <div className="component-wrapper">
        <h2>Starring</h2>
        <div className="cols">
          <ul>
            {characters.map(d => (
              <li key={d.img}>
                <div className="character-title">
                  <svg width={width} height={height}>
                    <defs>{d.defs}</defs>
                    <g transform={`translate(${width / 2},${height / 2})`}>
                      <Face
                        {...{
                          key: `img_${d.img}`,
                          deltaX: 0,
                          width: width * 2,
                          index: 0,
                          n: 1,
                          face: d.img,
                        }}
                      />
                    </g>
                  </svg>
                  <h2>{d.name}</h2>
                </div>
                <div className="character-description">
                  <p>{d.text}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Starring;
