import React, {Component} from 'react';
import './Ridgeline.css';
import {scaleLinear} from 'd3-scale';
import {area as d3Area, line as d3Line, curveCatmullRom} from 'd3-shape';
import * as moment from 'moment';

class Ridgeline extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();

    this.draw = this.draw.bind(this);
    this.viewbox = this.viewbox.bind(this);

    this.margins = [0, 0, 30, 0];
    this.labelsDistance = 40;

    this.state = {
      paths: {
        axis: [],
        series: [],
      },
      size: {
        h: 0,
        w: 0,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.viewbox, false);
    this.viewbox();
  }

  componentDidUpdate(pProps) {
    if (pProps !== this.props) {
      this.viewbox();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.viewbox, false);
  }

  draw() {
    if (this.props.Series && this.props.Options) {
      // Define scaleX and scaleY
      const bottomBaseY = this.state.size.h - this.margins[2];
      // const topBaseY = bottomBaseY - height - 20;
      const leftBaseX = this.margins[3];
      const rightBaseX = this.state.size.w - this.margins[1];
      const aggregations = Object.keys(this.props.Options.legend);
      const height = Math.floor(
        (this.state.size.h - this.margins[0] - this.margins[2]) / aggregations.length,
      );
      // const distance = height / (aggregations.length - 1);

      let minX = this.props.Options.x.min;
      let maxX = this.props.Options.x.max;

      let minY = this.props.Options.y.min;
      let maxY = this.props.Options.y.max;

      if (!minX || !maxX) {
        const xValues = [];
        aggregations.forEach(aggregation => {
          this.props.Series[aggregation].forEach(day => {
            xValues.push(day.timestamp);
          });
        });
        minX = Math.min(...xValues);
        maxX = Math.max(...xValues);
        if (minX === maxX) {
          maxX = minX * 100 || 100;
        }
      }

      if (!minY || !maxY) {
        const yValues = [];
        aggregations.forEach(aggregation => {
          this.props.Series[aggregation].forEach(day => {
            yValues.push(day[this.props.Options.show]);
          });
        });
        minY = Math.min(...yValues);
        maxY = Math.max(...yValues);
        if (minY === maxY) {
          maxY = minY * 100 || 100;
        }
      }

      const scaleX = scaleLinear()
        .domain([minX, maxX])
        .range([leftBaseX, rightBaseX]);

      const scaleY = scaleLinear()
        .domain([minY, maxY])
        .range([bottomBaseY, bottomBaseY - height]);

      const line = d3Line()
        .x(point => point.x)
        .y(point => point.y)
        .curve(curveCatmullRom.alpha(0.5));

      const area = d3Area()
        .x0(point => point.x)
        .y1(point => point.y)
        .y0(scaleY(0))
        .curve(curveCatmullRom.alpha(0.5));
      const series = [];
      let points = [];
      aggregations.forEach((aggregation, index) => {
        points = [];
        this.props.Series[aggregation].forEach(day => {
          points.push({
            x: scaleX(day.timestamp),
            y: scaleY(day[this.props.Options.show]),
          });
        });
        series.push({
          label: {
            text: this.props.Options.legend[aggregation],
            x: 5,
            y: bottomBaseY - 5,
          },
          line: {
            x1: 0,
            y1: scaleY(minY),
            x2: scaleX(maxX),
            y2: scaleY(minY),
          },
          name: aggregation,
          paths: {
            area: area(points),
            line: line(points),
          },
          transform: `translate(0 ${0 - height * index})`,
        });
      });

      // Lines and labels
      const axis = [];
      let prevX = 0;

      this.props.Series[aggregations[0]].forEach(day => {
        const x = scaleX(parseInt(day.timestamp, 10));
        const y1 = this.margins[0];
        const y2 = this.state.size.h - this.margins[2];
        if (
          (axis.length === 0 || Math.abs(x - prevX) >= this.labelsDistance) &&
          x < this.state.size.w - this.labelsDistance &&
          x >= this.labelsDistance
        ) {
          axis.push({
            label: {
              label: moment(day.date_short).format('DD/MM'),
              x,
              y: y2 + 25,
            },
            line: {
              x1: x,
              x2: x,
              y1,
              y2: y2 + 10,
            },
          });
          prevX = x;
        }
      });

      this.setState({
        loading: false,
        paths: {axis, series},
      });
    }
  }

  viewbox() {
    const svgWrapper = this.chart.current;
    const size = {
      h: svgWrapper.offsetHeight,
      w: svgWrapper.offsetWidth,
    };
    this.setState({size}, () => {
      this.draw();
    });
  }

  render() {
    return (
      <div className="Ridgeline" ref={this.chart}>
        <svg
          viewport={`0 0 ${this.state.size.w} ${this.state.size.h}`}
          preserveAspectRatio="xMidYMid meet"
          width={this.state.size.w}
          height={this.state.size.h}
        >
          <g className="series">
            {this.state.paths.series.map(serie => {
              return (
                <g key={serie.name} className={`serie ${serie.name}`} transform={serie.transform}>
                  <text
                    x={serie.label.x}
                    y={serie.label.y}
                    textAnchor="start"
                    alignmentBaseline="top"
                    dominantBaseline="top"
                  >
                    {serie.label.text}
                  </text>
                  <path d={serie.paths.area} className="area" />
                  <path d={serie.paths.line} className="line" />
                  <line
                    x1={serie.line.x1}
                    y1={serie.line.y1}
                    x2={serie.line.x2}
                    y2={serie.line.y2}
                  />
                </g>
              );
            })}
          </g>
          <g>
            {this.state.paths.axis.map((element, index) => {
              return (
                <g key={`e${index}`}>
                  <line
                    x1={element.line.x1}
                    x2={element.line.x2}
                    y1={element.line.y1}
                    y2={element.line.y2}
                    className="dateLine"
                  />
                  <text
                    key={element.label.x}
                    x={element.label.x}
                    y={element.label.y}
                    textAnchor="middle"
                    alignmentBaseline="top"
                    dominantBaseline="top"
                    className="label"
                  >
                    {element.label.label}
                  </text>
                </g>
              );
            })}
          </g>
        </svg>
      </div>
    );
  }
}

export default Ridgeline;
