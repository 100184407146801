import React, {Component} from 'react';
import {scaleSqrt} from 'd3-scale';
import {extent} from 'd3-array';
import moment from 'moment';
import {getCoords, radialArea, SQRT2, colors, offset, SLUGS_MAP} from '../../../../Helpers/utils';

import {
  Face,
  ModiFaceDef,
  RahulFaceDef,
  MayawatiFaceDef,
  YadavFaceDef,
  MamataFaceDef,
  YogiFaceDef,
  BJPSymbolDef,
  CongressSymbolDef,
  AITCSymbolDef,
  ShivSenaSymbolDef,
  BSPSymbolDef,
  SPSymbolDef,
} from './faces';

// import Standfirst from '../Layout/Standfirst';
// import Keys from '../Layout/Keys';
import ArticleTexts from '../Layout/ArticleTexts';
import VisualTimeline from '../Layout/VisualTimeline';
import './bubbles.scss';

const popularityScale = scaleSqrt();

const MIN_ARTICLES_FOR_TICKS = 5;

// const MAX_SIDE = 300;

const sides = 4;
const SIDE = 30;
const SQUARE_MARGIN = 6;

// const daysToElections = Math.ceil(
//   Math.abs(new Date('4/10/2019') - new Date()) / (1000 * 3600 * 24)
// );
// const visualTimelineTexts = [];

const getFace = (face, options) => {
  if (face === 'bjp') {
    return <Face {...{...options, face: 'BJPSymbol'}} />;
  }
  if (face === 'congress') {
    return <Face {...{...options, face: 'CongressSymbol'}} />;
  }
  if (face === 'aitc') {
    return <Face {...{...options, face: 'AITCSymbol'}} />;
  }
  if (face === 'shivsena') {
    return <Face {...{...options, face: 'ShivSenaSymbol'}} />;
  }
  if (face === 'bsp') {
    return <Face {...{...options, face: 'BSPSymbol'}} />;
  }
  if (face === 'sp') {
    return <Face {...{...options, face: 'SPSymbol'}} />;
  }
  if (face === 'narendra-modi') {
    return <Face {...{...options, face: 'ModiFace'}} />;
  }
  if (face === 'rahul-gandhi') {
    return <Face {...{...options, face: 'RahulFace'}} />;
  }
  if (face === 'mayawati') {
    return <Face {...{...options, face: 'MayawatiFace'}} />;
  }
  if (face === 'akhilesh-yadav') {
    return <Face {...{...options, face: 'YadavFace'}} />;
  }
  if (face === 'mamata-banerjee') {
    return <Face {...{...options, face: 'MamataFace'}} />;
  }
  if (face === 'yogi-adityanath') {
    return <Face {...{...options, face: 'YogiFace'}} />;
  }

  return null;
};

class Bubbles extends Component {
  constructor(props) {
    super(props);
    this.squareMap = [];
    this.currentScrollTop = 0;
    this.currentSquare = null;
    this.state = {
      cells: [],
      ticks: {},
      height: 0,
      visualTimelineTexts: [],
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', e => {
      this._handleScroll(e);
    });

    const update = () => {
      let found = null;
      // if(this.currentScrollTop > this.state.height) {
      //   this.deHighlightSquare(this.currentSquare);
      //   this.ArticleTexts.hideText(this.currentSquare);
      //   this.currentSquare = null;
      //   window.requestAnimationFrame(update);
      //   return;
      // }
      // if(this.currentScrollTop < this.state.height) {
      for (let i = 0; i < this.squareMap.length; i += 1) {
        if (this.squareMap[i][1] <= this.currentScrollTop) {
          [found] = this.squareMap[i];
        } else {
          break;
        }
      }

      if (!found) {
        this.deHighlightSquare(this.currentSquare);
        this.ArticleTexts.hideText(this.currentSquare);
        this.currentSquare = null;
      } else if (found !== this.currentSquare) {
        this.currentSquare = found;
        this.highlightSquare(this.currentSquare);
        this.ArticleTexts.showText(this.currentSquare);
      }
      // }
      window.requestAnimationFrame(update);
    };
    update();
  }

  componentWillReceiveProps(nextProps) {
    const {articles, width, hours, infoBlocks, showedHours, showedNews, filterBy} = nextProps;

    this.squareMap = [];

    const popExtent = extent(articles, d => +d.popularity);

    let maxSide = Math.round(width * 0.4); //  > 1600 ? MAX_SIDE * 1.5 : MAX_SIDE;
    let minSide = Math.round(width * 0.04);
    if (width < 572) {
      maxSide = Math.round(width * 0.75);
      minSide = Math.max(50, Math.round(width * 0.05));
    }
    popularityScale.domain(popExtent).range([minSide * minSide, maxSide * maxSide]);

    const ticks = {};
    const visualTimelineTexts = [];
    let prev = null;
    const firstArticle = articles[0];
    const squares = articles
      .filter((article, articleIndex) => {
        if (filterBy === 'numberOfNews') {
          return articleIndex <= showedNews;
        }

        return (
          moment(article.hour).toDate() >=
          +moment(firstArticle.hour)
            .subtract(showedHours, 'hours')
            .toDate()
        );
      })
      .map((d, i) => {
        const size = popularityScale(d.popularity);
        let side = Math.sqrt(size);
        let diag = SQRT2 * side;
        diag = Math.floor(diag / SIDE) * SIDE;
        side = diag / SQRT2;
        const points = getCoords(side, sides, SQUARE_MARGIN);
        const square = {
          id: i,
          side,
          size,
          diag: SQRT2 * side,
          points,
          sibling: prev,
          party: d.party,
          collections: d.collections,
          slugs: d.slugs,
          article: d,
        };
        prev = square;

        return square;
      });

    let height = 0;
    let availableSide = 0;

    const cells = squares.map(d => {
      if (!d.sibling) {
        d.position = [0, d.diag];
        availableSide = d.side;
        d.direction = 1;
        d.parent = d;
        d.position = [0, d.diag];

        ticks[d.article.hour] = {
          hour: moment(d.article.hour).format('ha'),
          date: moment(d.article.hour).format('DD MMM'),
          y: d.position[1],
        };
        // console.log(d.position[1], d.article.id)
        // this.squareMap[d.position[1]] = d.article.id;
        this.squareMap.push([d.article.id, d.position[1]]);

        return d;
      }
      availableSide -= d.side;
      if (d.sibling.parent.id === d.sibling.id) {
        d.parent = d.sibling.parent;
        d.direction = d.sibling.direction;
      } else {
        d.parent = d.sibling.parent;
        d.direction = -d.parent.direction;
      }

      d.position = [
        d.sibling.position[0] + (d.sibling.diag / 2) * d.direction,
        d.sibling.position[1] + d.diag / 2,
      ];

      if (
        d.position[0] - d.diag / 2 < -this.props.width / 2 ||
        d.position[0] + d.diag / 2 > this.props.width / 2
      ) {
        d.direction = -d.direction;
        d.position[0] = d.sibling.position[0] + (d.sibling.diag / 2) * d.direction;
      }

      if (availableSide <= d.side) {
        d.parent = d;
        d.direction = -d.sibling.direction;
        availableSide = d.side;
      }

      if (!ticks[d.article.hour] && hours[d.article.hour].articles > MIN_ARTICLES_FOR_TICKS) {
        ticks[d.article.hour] = {
          hour: moment(d.article.hour).format('ha'),
          date: moment(d.article.hour).format('DD MMM'),
          y: d.position[1],
        };
      }
      // console.log(d.position[1], d.article.id)
      this.squareMap.push([d.article.id, d.position[1]]);

      height = Math.max(height, d.position[1] + d.diag / 2);

      return d;
    });

    // console.log('TICKS', ticks);

    infoBlocks
      .filter(block => {
        if (filterBy === 'numberOfNews') {
          return block.value.sum <= showedNews;
        }

        return (
          moment(block.key).toDate() >=
          moment(firstArticle.hour)
            .subtract(showedHours, 'hours')
            .toDate()
        );
      })
      .forEach(d => {
        let closestDate = d.key;
        let delta = moment(closestDate).unix();
        Object.keys(ticks).forEach(tick => {
          const diff = Math.abs(moment(d.key).unix() - moment(tick).unix());
          if (diff <= delta) {
            delta = diff;
            closestDate = tick;
          }
        });

        if (ticks[closestDate]) {
          visualTimelineTexts.push({
            date: d.key,
            total: d.value.total,
            y: ticks[closestDate].y,
            data: d.value.slugs,
            closestDate,
            id: `vtext_${moment(closestDate).format('YYYY-MM-DD')}_${d.key}`,
            sentiments: d.value.sentiments,
            politicians: Object.entries(d.value.politicians)
              .filter(p => p[0] !== 'Yogi Adityanath' && p[0] !== 'Arvind Kejriwal') // don't want to see them in the description
              .map(politician => ({
                name: politician[0],
                className: politician[0].toLowerCase().replace(/\s/, '-'),
                coalition: SLUGS_MAP[politician[0]],
                value: politician[1],
                perc: politician[1] / d.value.total,
              }))
              .sort((a, b) => b.value - a.value),
          });
        }
      });

    // console.log('this.squareMap', this.squareMap);

    this.setState({
      // daysToElections,
      cells,
      // articles,
      ticks,
      height,
      visualTimelineTexts,
    });
  }

  highlightSquare(id) {
    this.deHighlightSquare(this.selected);
    this.selected = id;
    this.bubbleSVG.querySelector(`g#square${id}`).classList.add('highlight-article');
  }

  deHighlightSquare(id) {
    // if(id == undefined) {
    if (typeof id === 'undefined' || id === null) {
      return;
    }
    this.bubbleSVG.querySelector(`g#square${id}`).classList.remove('highlight-article');
  }

  selectArticle(id) {
    this.ArticleTexts.showText(id);
  }

  deselectArticle(id) {
    this.ArticleTexts.hideText(id);
  }

  _handleScroll() {
    const top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
    this.currentScrollTop = Math.floor(
      (!Number.isNaN(top) ? top : 0) -
        offset(this.bubbleSVG).top +
        Math.floor(window.innerHeight / 2),
    );
    // console.log(this.currentScrollTop)
  }

  render() {
    const {cells, ticks, visualTimelineTexts} = this.state;
    const gradients = {};
    const rects = cells.map(d => {
      let bgColor = colors[d.slugs[0]];
      const gradientID = d.slugs.join('-');
      if (d.slugs.length > 1) {
        if (!gradients[gradientID]) {
          const step = 100 / (d.slugs.length - 1);
          gradients[gradientID] = (
            <linearGradient key={gradientID} id={gradientID}>
              {d.slugs.map((slug, i) => (
                <stop key={`stop-${slug}`} offset={`${i * step}%`} stopColor={colors[slug]} />
              ))}
            </linearGradient>
          );
        }
        bgColor = `url(#${gradientID})`;
      }

      // const sentiment = +d.article.sentiment;
      const faces = d.article.politicians.length ? d.article.politicians : d.article.parties;

      return (
        <g
          key={d.article.id}
          id={`square${d.article.id}`}
          className={`article ${gradientID}`}
          transform={`translate(${d.position[0]},${d.position[1]})`}
          data-pop={d.article.popularity}
          data-size={d.size}
        >
          <path className="square" d={`${radialArea(d.points)}Z`} style={{fill: bgColor}} />
          {faces.map((politician, i) => {
            // if(d.diag < 30) {
            //   return null;
            // }
            const deltaX = 0; // -(d.diag/(d.article.slugs.length*2)) * (d.article.slugs.length-1) + (d.diag/(d.article.slugs.length)) * i;

            return getFace(politician, {
              key: `${politician}_${d.article.id}`,
              deltaX,
              width: d.diag,
              index: i,
              n: faces.length,
            });
          })}
        </g>
      );
    });
    /*
    {sentiment === 0 && (
      <ModiFace position={d.position} width={d.diag} />
    )}
    {sentiment > 0 && (
      <PositiveFace position={d.position} width={d.diag} />
    )}
    {sentiment < 0 && (
      <RahulFace position={d.position} width={d.diag} />
    )}
    */
    const timelineTicks = Object.values(ticks).map(tick => {
      const tickStyle = {
        top: `${tick.y}px`,
      };

      return (
        <li key={`${tick.date}-${tick.y}`} style={tickStyle}>
          <span>
            {tick.hour}
            <br />
            {tick.date}
          </span>
        </li>
      );
    });

    const slugs = {};
    this.props.articles.forEach(article => {
      article.slugs.forEach(slug => {
        if (!slugs[slug]) {
          slugs[slug] = 0;
        }
        slugs[slug] += 1;
      });
    });

    const slugsSortedList = Object.entries(slugs).sort((a, b) => b[1] - a[1]);

    return (
      <div className="faces-parallax">
        {/* <Standfirst showingHours={this.props.showingHours} articles={this.props.articles} slugsSortedList={slugsSortedList}/>
        <Keys showingHours={this.props.showingHours} articles={this.props.articles} slugsSortedList={slugsSortedList}/> */}
        <VisualTimeline texts={visualTimelineTexts} slugsSortedList={slugsSortedList} />
        <section className="squares-container parallax-layer parallax-layer-base">
          <ul className="timeline">{timelineTicks}</ul>
          <svg
            ref={el => {
              this.bubbleSVG = el;
            }}
            width={this.props.width}
            height={this.state.height}
            className="squares-svg"
          >
            <defs>
              {Object.values(gradients)}
              <ModiFaceDef />
              <RahulFaceDef />
              <MayawatiFaceDef />
              <YadavFaceDef />
              <MamataFaceDef />
              <YogiFaceDef />
              <BJPSymbolDef />
              <CongressSymbolDef />
              <AITCSymbolDef />
              <ShivSenaSymbolDef />
              <BSPSymbolDef />
              <SPSymbolDef />
            </defs>
            <g transform={`translate(${this.props.width / 2},0)`}>{rects}</g>
          </svg>
          <section
            className="squares-container parallax-layer parallax-layer-base"
            style={{position: 'absolute', top: 0, width: '100%'}}
          >
            <ArticleTexts
              ref={el => {
                this.ArticleTexts = el;
              }}
              articles={this.state.cells}
              width={this.props.width}
              selected={this.state.selected}
            />
          </section>
        </section>
      </div>
    );
  }
}

export default Bubbles;
