import React, {Component} from 'react';
import Measure from 'react-measure';
import moment, {ISO_8601} from 'moment';
import {nest} from 'd3-collection';
import Bubbles from '../components/Bubbles';
import {
  cleanUp,
  areas,
  calculatePopularity,
  TAGS,
  POLITICIANS,
  PARTY_NAMES,
} from '../../../Helpers/utils';
import './faces-container.scss';

const FETCHEDHOURS = 24;

const HOURS_PER_PAGE = 6;
const NEWS_PER_PAGE = 50;

const cleanURL = function cleanURL(url) {
  let output = '';
  try {
    const base64EncodedURL = url.replace(/https:\/\/india.elezioni.io\/click\?u=/gi, '');
    output = window.atob(base64EncodedURL);
  } catch (error) {
    // console.error(error);
    output = '#';
  }

  return output;
};

class FacesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      infoBlocks: [],
      dimensions: {
        width: 0,
        height: 0,
      },
      showedHours: HOURS_PER_PAGE,
      showedNews: NEWS_PER_PAGE,
    };
  }

  componentDidMount() {
    this.loadData(FETCHEDHOURS);
  }

  // loadData(showedHours) {
  loadData() {
    // fetch(`https://india.elezioni.io/v0/realtime/by-hour/${showedHours}`)
    // fetch('https://india.elezioni.io/v0/realtime/by-day/2019-05-23')
    fetch(`/data/faces.json`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        // console.log("parsed json", data);
        const blockTimeRanges = [];
        const hours = {};
        const prevs = {};
        let articles = data.data.articles
          .filter(d => typeof d.dbid === 'undefined' && (+d.tw_reach > 0 || +d.fb_shares > 0))
          // .filter(d => +d.popularity_logs > 0)
          .sort((a, b) => +new Date(b.date_published) - +new Date(a.date_published))
          .map((article, i) => {
            article.index = i;

            article.original_url = article.url;
            article.url = cleanURL(article.original_url);

            article.date_published = moment(article.date_published, ISO_8601)
              .subtract(60 * 4 + 30, 'minutes')
              .format('YYYY-MM-DD HH:mm');

            // time in hours
            const hour = moment(article.date_published, ISO_8601)
              .add(60 * 2, 'minutes')
              .format('YYYY-MM-DD HH:00');
            article.hour = hour;

            if (!hours[hour]) {
              hours[hour] = {hour, articles: 1};
            } else {
              hours[hour].articles += 1;
            }

            // time in blockTimeRange
            const blockTimeRange = moment(article.date_published, ISO_8601).format(
              'YYYY-MM-DD 23:59:59',
            );
            article.blockTimeRange = blockTimeRange;
            if (blockTimeRanges.indexOf(blockTimeRange) === -1) {
              blockTimeRanges.push(blockTimeRange);
            }

            article.originalTitle = article.title;
            article.title = cleanUp(article.title);
            article.original_slugs = article.collections
              .map(d => d.slug)
              .sort((a, b) => areas.indexOf(a) - areas.indexOf(b));

            // const slugsFromNames = Object.keys(names).filter(name => ~article.tags.indexOf(name)).map(name => names[name]);
            // const slugs = [...article.slugs, ...slugsFromNames].filter((value, index, self) => self.indexOf(value) === index );
            article.slugs = [];
            article.politicians = [];
            article.parties = [];
            article.tags.forEach(tag => {
              Object.keys(TAGS).forEach(d => {
                if (TAGS[d].indexOf(tag) > -1) {
                  if (article.slugs.indexOf(d) === -1) {
                    article.slugs.push(d);
                  }
                }
              });
              Object.keys(POLITICIANS).forEach(d => {
                if (POLITICIANS[d].indexOf(tag) > -1) {
                  const politician = d.toLowerCase().replace(/\s/, '-');
                  if (article.politicians.indexOf(politician) === -1) {
                    article.politicians.push(politician);
                  }
                }
              });
              Object.keys(PARTY_NAMES).forEach(d => {
                if (PARTY_NAMES[d].indexOf(tag.toLowerCase()) > -1) {
                  const party = d.toLowerCase().replace(/\s/, '-');
                  if (article.parties.indexOf(party) === -1) {
                    article.parties.push(party);
                  }
                }
              });
            });

            const already = [];
            article.prevs = article.collections
              .map(collection => {
                const prev = prevs[collection.slug];
                prevs[collection.slug] = article;
                if (prev) {
                  already.push(prev.id);
                }

                return prev;
              })
              .filter(d => typeof d !== 'undefined');

            article.old_popularity = article.popularity;
            // console.log(article)
            article.popularity = calculatePopularity(article);

            return article;
          });

        articles = articles.filter(d => d.slugs.length);
        let sum = 0;
        const infoBlocks = nest()
          .key(d => d.blockTimeRange)
          .rollup(leaves => {
            const slugs = {};
            const sentiments = {};
            const politicians = {};

            leaves.forEach(d => {
              d.slugs.forEach(slug => {
                if (!slugs[slug]) {
                  slugs[slug] = 0;
                }
                slugs[slug] += 1;
              });
              d.tags.forEach(tag => {
                Object.keys(POLITICIANS).forEach(politician => {
                  if (!politicians[politician]) {
                    politicians[politician] = 0;
                  }
                  if (POLITICIANS[politician].indexOf(tag) > -1) {
                    politicians[politician] += 1;
                  }
                });
              });
            });
            leaves.forEach(d => {
              if (!sentiments[d.sentiment]) {
                sentiments[d.sentiment] = 0;
              }
              sentiments[d.sentiment] += 1;
            });
            sum += leaves.length;

            return {
              total: leaves.length,
              sum: sum - leaves.length,
              slugs,
              sentiments,
              politicians,
            };
          })
          .entries(articles);

        this.setState({articles, hours, infoBlocks});
      })
      .catch(ex => {
        console.log('parsing failed', ex);
      });
  }

  showMore() {
    // console.log('SHOW MORE');
    // this.loadData(12);
    const {showedHours, showedNews} = this.state;
    this.setState({
      showedHours: showedHours + HOURS_PER_PAGE,
      showedNews: showedNews + NEWS_PER_PAGE,
    });
  }

  render() {
    const {articles, infoBlocks, hours, showedHours, showedNews} = this.state;

    return (
      <div className="faces">
        <Measure
          bounds
          onResize={contentRect => {
            if (contentRect.bounds.width !== this.state.dimensions.width) {
              this.setState({
                dimensions: {
                  top: contentRect.bounds.top,
                  width: contentRect.bounds.width,
                  height: contentRect.bounds.height,
                  contentRect,
                },
              });
            }
          }}
        >
          {({measureRef}) => (
            <div className="faces-container" ref={measureRef}>
              <Bubbles
                articles={articles}
                infoBlocks={infoBlocks}
                hours={hours}
                fields={{y: 'index'}}
                outlines={20}
                showedHours={showedHours}
                showedNews={showedNews}
                filterBy="numberOfNews" // numberOfHours
                {...this.state.dimensions}
              />
              <div
                className="show-more"
                onKeyPress={() => this.showMore()}
                role="button"
                tabIndex="0"
                onClick={() => this.showMore()}
              >
                <span>show more</span>
              </div>
            </div>
          )}
        </Measure>
      </div>
    );
  }
}

export default FacesContainer;
