import React, {Component} from 'react';
import './StreamGraph.css';
import {extent} from 'd3-array';
import {scaleLinear} from 'd3-scale';
// import {Delaunay} from 'd3-delaunay';
// import {polygonArea} from 'd3-polygon';
import {area as d3Area, curveCatmullRom, stack, stackOffsetSilhouette} from 'd3-shape';
import moment from 'moment';

class StreamGraph extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();

    this.draw = this.draw.bind(this);
    this.viewbox = this.viewbox.bind(this);

    this.labelsDistance = 50;

    this.state = {
      paths: {
        bullets: [],
        labels: [],
        lines: [],
        series: [],
      },
      // show: 'numbers',
      size: {
        h: 0,
        w: 0,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.viewbox, false);
    this.viewbox();
  }

  componentDidUpdate(pProps, pState) {
    if (pProps !== this.props) {
      this.viewbox();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.viewbox, false);
  }

  draw() {
    if (
      this.props.Data &&
      this.props.Options.show &&
      this.props.Options.show !== '' &&
      this.props.Options.series &&
      this.props.Options.series.length > 0
    ) {
      // Scales
      const scaleX = scaleLinear()
        .domain(extent(this.props.Data[this.props.Options.show].map(child => +child.day)))
        .range([0, this.state.size.w]);

      const scaleY = scaleLinear()
        .domain([this.props.Options.max.y, -1 * this.props.Options.max.y])
        .range([this.state.size.h, 0]);

      // Series
      const stackedData = stack()
        .offset(stackOffsetSilhouette)
        .keys(this.props.Options.series)(this.props.Data[this.props.Options.show]);
      const area = d3Area()
        .x(point => scaleX(point.data.day))
        .y0(point => scaleY(point[0]))
        .y1(point => scaleY(point[1]))
        .curve(curveCatmullRom.alpha(0.5));
      const series = [];
      this.props.Options.series.forEach((serie, index) => {
        series.push({
          name: serie,
          path: area(stackedData[index]),
        });
      });

      // const points = [];
      // this.props.Options.series.forEach(serie => {
      //   this.props.Data[this.props.Options.show].forEach(d => {
      //     const point = [scaleX(d.day), scaleY(d[serie])];
      //     point.data = {...d, serie};
      //     points.push(point)
      //   })
      // })

      // const points = [].concat(
      //   ...stackedData.map((serie, serieIndex) => {
      //     return serie.map(d => {
      //       const point = [scaleX(d.data.day), (scaleY(d[1]) + scaleY(d[0])) / 2];
      //       point.serie = this.props.Options.series[serieIndex];
      //
      //       return point;
      //     });
      //   }),
      // );
      // console.log('POINTS', stackedData, points);
      // const pointsYExtent = extent(points, d => d[1]);
      // const delaunay = Delaunay.from(points);
      // const voronoi = delaunay.voronoi([0, pointsYExtent[0], this.state.size.w, pointsYExtent[1]]);

      // const maxCells = {};
      // for (const [i, cell] of [...voronoi.cellPolygons()].entries()) {
      //   if (points[i][0] >= this.state.size.w / 4 && points[i][0] <= (this.state.size.w * 3) / 4) {
      //     console.log(i, cell, points[i]);
      //     const pArea = Math.abs(polygonArea(cell));
      //     const serie = points[i].serie;
      //     if (!maxCells[serie] || maxCells[serie].area <= pArea) {
      //       maxCells[serie] = {
      //         ...points[i],
      //         polygon: cell,
      //         area: pArea,
      //       };
      //     }
      //   }
      // }
      //
      // console.log('maxCells', maxCells);

      // Days
      const centerY = this.state.size.h / 2;
      const labels = [];
      const lines = [];
      const bullets = [];
      const distance = 10;
      let prevX = 0;
      this.props.Data[this.props.Options.show].forEach(entry => {
        const x = scaleX(entry.day);
        if (
          Math.abs(prevX - x) >= this.labelsDistance &&
          x <= this.state.size.w - this.labelsDistance
        ) {
          labels.push({
            label: moment(+entry.day * 1000).format('MMM Do'),
            x,
            y: centerY,
          });
          lines.push({
            x1: x,
            y1: 0,
            x2: x,
            y2: centerY - distance,
          });
          lines.push({
            x1: x,
            y1: centerY + distance,
            x2: x,
            y2: this.state.size.h,
          });
          bullets.push({
            x1: x - distance / 2,
            y1: centerY + distance,
            x2: x + distance / 2,
            y2: centerY + distance,
          });
          bullets.push({
            x1: x - distance / 2,
            y1: centerY - distance,
            x2: x + distance / 2,
            y2: centerY - distance,
          });
          prevX = x;
        }
      });

      this.setState({
        // loading: false,
        paths: {series, lines, labels, bullets},
        // voronoi,
        // points,
        // maxCells,
      });
    }
  }

  viewbox() {
    const svgWrapper = this.chart.current;
    const size = {
      h: svgWrapper.offsetHeight,
      w: svgWrapper.offsetWidth,
    };
    this.setState({size}, () => {
      this.draw();
    });
  }

  render() {
    const legend = this.props.Options.legend;

    return (
      <div className="StreamGraph" ref={this.chart}>
        <svg
          viewport={`0 0 ${this.state.size.w} ${this.state.size.h}`}
          preserveAspectRatio="xMidYMid meet"
          width={this.state.size.w}
          height={this.state.size.h}
        >
          <g>
            {this.state.paths.series.map((serie, index) => {
              return (
                <g key={serie.name}>
                  <path d={serie.path} className={`area ${serie.name}`} />
                </g>
              );
            })}
          </g>
          <g className="lines">
            {this.state.paths.lines.map((line, index) => {
              return (
                <line
                  key={index}
                  x1={line.x1}
                  y1={line.y1}
                  x2={line.x2}
                  y2={line.y2}
                  className="line"
                />
              );
            })}
          </g>
          <g className="bullets">
            {this.state.paths.bullets.map((bullet, index) => {
              return (
                <line
                  key={index}
                  x1={bullet.x1}
                  y1={bullet.y1}
                  x2={bullet.x2}
                  y2={bullet.y2}
                  className="bullet"
                />
              );
            })}
          </g>
          <g className="labels">
            {this.state.paths.labels.map((label, index) => {
              return (
                <text
                  key={index}
                  x={label.x}
                  y={label.y}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  dominantBaseline="middle"
                  className="label"
                >
                  {label.label}
                </text>
              );
            })}
          </g>
          {this.state.voronoi && (
            <g>
              <path d={this.state.voronoi.render()} style={{stroke: '#000'}} />
              {this.state.points.map(d => (
                <circle cx={d[0]} cy={d[1]} r="2" />
              ))}
            </g>
          )}
          {this.state.maxCells && (
            <g className="dynamic-labels">
              {Object.values(this.state.maxCells).map(d => (
                <g key={`label-${d.serie}`} transform={`translate(${d[0]},${d[1]})`}>
                  <text x={0} y={0} dy="0.25em">
                    {d.serie}
                  </text>
                </g>
              ))}
            </g>
          )}
        </svg>
        <div className="legend">
          <ul>
            {this.state.paths.series.map((serie, index) => {
              return (
                <li key={serie.name}>
                  <span className={`circle ${serie.name}`}>{legend[serie.name]}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default StreamGraph;
