import React from 'react';
import {stack, stackOrderDescending} from 'd3-shape';
import {sum as d3Sum, mean as d3Mean, quantile} from 'd3-array';

const aggregation = 'avg';

export default function Tile(props) {
  const {data, fields} = props;

  const keys = Object.keys(data);

  const averageValues = keys.map(key => {
    const sortedActivities = [...data[key]]
      .sort((a, b) => a[fields.y] - b[fields.y])
      .map(d => d[fields.y]);

    return {
      key,
      avg: d3Mean(data[key], d => d[fields.y]),
      p90: quantile(sortedActivities, 0.9),
    };
  });

  const dataForAvgStack = {};

  averageValues.forEach(d => {
    const avgSum = d3Sum(averageValues, value => value.avg);
    const p90Sum = d3Sum(averageValues, value => value.p90);
    d.avgPerc = avgSum > 0 ? d.avg / avgSum : 0;
    d.p90Perc = p90Sum > 0 ? d.p90 / p90Sum : 0;
  });
  averageValues.forEach(d => {
    dataForAvgStack[d.key] = d[`${aggregation}Perc`];
  });

  const stackedAggregated = stack()
    .order(stackOrderDescending)
    .keys(keys)([dataForAvgStack]);

  return (
    <ul className="slices">
      {stackedAggregated.map(d => {
        const h = d[0][1] - d[0][0];
        const sliceStyle = {
          top: `${100 - Math.ceil(100 * d[0][1])}%`,
          height: `${Math.ceil(100 * h)}%`,
        };

        return <li key={d.key} rel={d.key} className={d.key} style={sliceStyle} />;
      })}
    </ul>
  );
}
