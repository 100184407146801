import React, {Component} from 'react';
import axios from 'axios';

import StreamGraph from '../StreamGraph/StreamGraph';
import {prettyNames} from '../../Helpers/utils';
import './OverviewStreamGraph.css';

class OverviewStreamGraph extends Component {
  constructor(props) {
    super(props);

    this.endpoint = '/data/history.json'; // 'https://india.elezioni.io/v0/stats/history';

    this.load = this.load.bind(this);

    this.state = {
      coalitions: [],
      // loading: true,
      max: {},
      series: [],
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    axios
      .get(this.endpoint, {withCredentials: false})
      .then(response => {
        if (response.status === 200 && !response.data.error) {
          const series = {
            activity: [],
            popularity: [],
          };
          const coalitions = Object.keys(response.data.data.collections);
          coalitions.forEach(coalition => {
            response.data.data.collections[coalition].forEach((day, index) => {
              if (series.activity[index]) {
                series.activity[index][coalition] = day.activity;
              } else {
                const obj = {
                  day: day.timestamp,
                };
                obj[coalition] = day.activity;
                series.activity.push(obj);
              }
              if (series.popularity[index]) {
                series.popularity[index][coalition] = day.popularity;
              } else {
                const obj = {
                  day: day.timestamp,
                };
                obj[coalition] = day.popularity;
                series.popularity.push(obj);
              }
            });
          });
          this.setState({
            coalitions,
            legend: {
              mayawati: prettyNames.mahagathbandhan,
              nda: prettyNames.nda,
              upa: prettyNames.upa,
              others: prettyNames.others,
            },
            // loading: false,
            max: response.data.data.max,
            series,
          });
        } else {
          console.log(`An error occourred ${response.statusText} ${response.data.errormessage}`);
        }
      })
      .catch(error => {
        console.log(`Error ${error}`);
      });
  }

  render() {
    return (
      <div className="OverviewStreamGraph">
        <div className="component-wrapper">
          <h2>The press coverage</h2>
          <div className="cols">
            <p>
              People started to go to the polls
              <span role="img" aria-label="Polls">
                🗳️
              </span>{' '}
              and the debate got hotter
              <span role="img" aria-label="Fire">
                🔥
              </span>
              The number of articles in the news about the elections raised
              <span role="img" aria-label="increasing chart">
                📈
              </span>{' '}
              day by day.
            </p>
            <p>
              The following chart shows the evolution of the space the news media{' '}
              <span role="img" aria-label="Newspaper">
                📰
              </span>
              <span role="img" aria-label="TV">
                📺
              </span>
              <span role="img" aria-label="Satellite">
                📡
              </span>{' '}
              dedicated to the Indian General Elections 2019 and how each group of parties shared
              this arena.
            </p>
          </div>
        </div>
        <div className="chart--wrapper">
          <figure className="OverviewStreamGraph--chart">
            <div className="in">
              <div className="OverviewStreamGraph--wrapper">
                <StreamGraph
                  Data={this.state.series}
                  Options={{
                    max: {y: this.state.max.activity},
                    show: 'activity',
                    series: this.state.coalitions,
                    legend: this.state.legend,
                  }}
                />
              </div>
            </div>
          </figure>
        </div>
        <div className="component-wrapper">
          <div className="cols">
            <p>
              While the <span className="legend-nda">NDA</span> and the{' '}
              <span className="legend-upa">UPA</span> clearly led the bloc as the most covered
              topics in the news &mdash; with the NDA as front-runner &mdash; the{' '}
              <span className="legend-mahagathbandhan">Mahagathbandhan</span>, a political alliance
              of regional parties, represented mainly by <b>Mayawati</b> of the{' '}
              <b>Bahujan Samaj Party</b> and <b>Akhilesh Yadav</b> of the <b>Samajwadi Party</b>,
              struggled to get some titles on the papers.
            </p>
            <p>
              Other political leaders, including <b>Mamata Banerjee</b> of the{' '}
              <span className="legend-others">All India Trinamool Congress</span>, were given less
              space by the press, but nevertheless, they fiercely fought
              <span role="img" aria-label="cross swords">
                ⚔️
              </span>{' '}
              for some visibility.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewStreamGraph;
