import React from 'react';
import './social.scss';

const Social = props => {
  const {status = 'default'} = props;

  return (
    <div className="social-paragraph">
      <div className="component-wrapper">
        <div className="cols">
          {status === 'intro' && (
            <p>
              Scroll down for more fun <span role="img" aria-label="increasing chart">
                📈
              </span>
              <span role="img" aria-label="fun">
                👯
              </span>
              <span role="img" aria-label="bar chart">
                📊
              </span><br/>But if you already like this
              <span role="img" aria-label="love">
                🥰
              </span>
              , why not share it with the rest of the world
              <span role="img" aria-label="World">
                🌏
              </span>
              ?
            </p>
          )}
          {status === 'default' && (
            <p>
              You got all the way down here
              <span role="img" aria-label="Thanks">
                🙏
              </span>
              , why not share it with the rest of the world
              <span role="img" aria-label="World">
                🌏
              </span>
              ?
            </p>
          )}
          <div className="social-buttons">
            <a
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              className="twitter-share-button"
              data-size="large"
              data-text="How are the news covering the Indian General Elections 2019? Discover it in this new visual exploration from @VisualizeNews"
              data-url="https://india.visualize.news"
              data-hashtags="LokSabhaElections2019"
              data-show-count="false"
            >
              Tweet
            </a>
            <div
              className="fb-share-button"
              data-href="https://india.visualize.news"
              data-layout="button"
              data-size="large"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Findia.visualize.news%2F&amp;src=sdkpreparse"
                className="fb-xfbml-parse-ignore"
              >
                Condividi
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
